var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "create-alert",
      attrs: {
        visible: _vm.visible,
        title: "创建模板",
        width: "700px",
        height: "400px",
        "modal-append-to-body": false,
        "append-to-body": true,
        "close-on-click-modal": false,
      },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [_c("en-button", { on: { click: _vm.submit } }, [_vm._v(" 确定 ")])],
        1
      ),
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "80px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模板名称", prop: "templateName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入", maxlength: "20" },
                model: {
                  value: _vm.templateName,
                  callback: function ($$v) {
                    _vm.templateName = $$v
                  },
                  expression: "templateName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "保存金额", prop: "amoutFlag", align: "left" } },
            [
              _c("el-switch", {
                attrs: { "active-value": 0, "inactive-value": 1 },
                model: {
                  value: _vm.amoutFlag,
                  callback: function ($$v) {
                    _vm.amoutFlag = $$v
                  },
                  expression: "amoutFlag",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }