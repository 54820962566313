var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "file-li",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.download.apply(null, arguments)
        },
      },
    },
    [
      _c("en-icon", {
        staticClass: "icon",
        attrs: {
          name: _vm.fileIcon[_vm.attr.extension].icon,
          color: _vm.fileIcon[_vm.attr.extension].color,
          size: "small",
        },
      }),
      _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.attr.name))]),
      !_vm.disable
        ? _c("en-icon", {
            staticClass: "close-btn",
            attrs: { name: "shanchu-liebiao", size: "small" },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.closeClick.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }