var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "voc-detail-alert",
      attrs: { fullscreen: "", "append-to-body": "", visible: _vm.visible },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "title-wrap", attrs: { slot: "title" }, slot: "title" },
        [
          _c("div", { staticClass: "left" }, [_vm._v(_vm._s(_vm.title))]),
          _c(
            "div",
            { staticClass: "right" },
            [
              _vm.operationType === 5
                ? _c("status-model", { attrs: { status: "004" } })
                : _c("status-model", {
                    attrs: { status: _vm.vcdata.voucherData.status },
                  }),
              _vm.operationType !== 5
                ? _c(
                    "el-dropdown",
                    { on: { command: _vm.handleCommand } },
                    [
                      _c(
                        "span",
                        { staticClass: "el-dropdown-link" },
                        [
                          _c(
                            "en-button",
                            {
                              attrs: {
                                type: "text",
                                icon: "shoucang-kuaijiecaozuo",
                                "icon-size": 16,
                                "icon-color": "#F8C641",
                              },
                            },
                            [_vm._v(" 收藏 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("el-dropdown-item", { attrs: { command: "cj" } }, [
                            _vm._v(" 创建 "),
                          ]),
                          _c("el-dropdown-item", { attrs: { command: "yy" } }, [
                            _vm._v(" 引用 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "en-loading",
              rawName: "v-en-loading",
              value: _vm.enLoading,
              expression: "enLoading",
            },
          ],
          staticClass: "voc-detail-wrap",
        },
        [
          _vm.operationType === 1
            ? _c("div", { staticClass: "top-row" }, [
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("所属账套：")]),
                    _c("span", [_vm._v(_vm._s(_vm.vcHeader.accountName))]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("记账主体：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.vcHeader.accountingEntityName)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("会计期间：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.vcHeader.accountingPeriodName)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c(
                    "span",
                    [
                      _c("span", [_vm._v("记账日期：")]),
                      _c("en-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择日期时间",
                          format: "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.produceDateChange },
                        model: {
                          value: _vm.vcHeader.produceDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.vcHeader, "produceDate", $$v)
                          },
                          expression: "vcHeader.produceDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("凭证字号：")]),
                    _c("span", [_vm._v("自动生成")]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("凭证来源：")]),
                    _c("span", [_vm._v("无")]),
                  ]),
                ]),
              ])
            : _vm.operationType === 5
            ? _c("div", { staticClass: "top-row" }, [
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("凭证字号：")]),
                    _c("span", [_vm._v("自动生成")]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("凭证来源：")]),
                    _c("span", [_vm._v(_vm._s(_vm.chargeVoucherSourceName))]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("所属账套：")]),
                    _c("span", [_vm._v(_vm._s(_vm.vcHeader.accountName))]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("记账主体：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.vcHeader.accountingEntityName)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c(
                    "span",
                    [
                      _c("span", [_vm._v("记账日期：")]),
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "选择日期时间",
                          format: "yyyy-MM-dd",
                          "picker-options": _vm.pickerOptions,
                        },
                        on: { change: _vm.produceDateChange },
                        model: {
                          value: _vm.vcHeader.produceDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.vcHeader, "produceDate", $$v)
                          },
                          expression: "vcHeader.produceDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "top-row-item" }, [
                  _c("span", [
                    _c("span", [_vm._v("会计期间：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.vcHeader.accountingPeriodName)),
                    ]),
                  ]),
                ]),
              ])
            : _c(
                "div",
                { staticClass: "top-row" },
                _vm._l(_vm.vcdata.voucherHeader, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "top-row-item",
                      attrs: { span: 4 },
                    },
                    [
                      item.fieldNameVariable === "voucherNo"
                        ? _c("span", [
                            _c("span", [_vm._v("凭证字号：")]),
                            _vm.operationType === 2
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.formatVoucherNo(item.value))
                                  ),
                                ])
                              : item.value && _vm.operationType !== 2
                              ? _c(
                                  "div",
                                  { staticStyle: { display: "inline-block" } },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          `${_vm.vcdata.voucherData.voucherDataTypeName}-`
                                        )
                                      ),
                                    ]),
                                    _c("el-input", {
                                      attrs: { type: "number" },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("span", [_vm._v("自动生成")]),
                          ])
                        : item.fieldNameVariable === "refId"
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(item.fieldName) + "：")]),
                            _c(
                              "span",
                              {
                                class:
                                  _vm.getRefIdName(item.value) &&
                                  _vm.getRefIdName(item.value) !== "无"
                                    ? "through"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.throughVoucher(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.getRefIdName(item.value)))]
                            ),
                          ])
                        : item.fieldNameVariable === "accountingEntity"
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(item.fieldName) + "：")]),
                            _c("span", [_vm._v(_vm._s(item.value.name))]),
                          ])
                        : item.fieldNameVariable === "accountingPeriod"
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(item.fieldName) + "：")]),
                            _c("span", [_vm._v(_vm._s(item.value.name))]),
                          ])
                        : item.type === 7 &&
                          item.fieldNameVariable !== "accountingPeriod"
                        ? _c(
                            "span",
                            [
                              _c("span", [
                                _vm._v(_vm._s(item.fieldName) + "："),
                              ]),
                              _vm.operationType === 2
                                ? _c("span", [
                                    _vm._v(_vm._s(item.value.slice(0, 10))),
                                  ])
                                : _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期时间",
                                      format: "yyyy-MM-dd",
                                      "picker-options": _vm.pickerOptions,
                                      disabled: _vm.fromCarryOver,
                                    },
                                    on: { change: _vm.produceDateChange },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  }),
                            ],
                            1
                          )
                        : item.type === 5
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(item.fieldName) + "：")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  (item.value &&
                                    item.value[0] &&
                                    item.value[0].name) ||
                                    ""
                                )
                              ),
                            ]),
                          ])
                        : [1, 3, 4].includes(item.type)
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(item.fieldName) + "：")]),
                            _c("span", [_vm._v(_vm._s(item.value))]),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
          _c(
            "div",
            { staticClass: "grid-wrap" },
            [
              _c("voucher-table", {
                attrs: {
                  isFor: _vm.vcdata.voucherData.isFor,
                  accountId: _vm.vcHeader.accountId,
                  subjectList: _vm.subjectList,
                  actionType: _vm.operationType,
                  voucherType: _vm.vcdata.voucherData.voucherType,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { class: ["middle-row", _vm.operationType === 2 ? "grey" : ""] },
            [
              _c("div", [
                _vm._v(
                  "制单人： " +
                    _vm._s(_vm.vcdata.voucherData.makeName || _vm.userInfo.name)
                ),
              ]),
              _c("div", [
                _vm._v(
                  "审核人： " + _vm._s(_vm.vcdata.voucherData.approveName || "")
                ),
              ]),
              _vm.operationType === 2
                ? _c("div", [
                    _c("span", [
                      _vm._v("附单据：" + _vm._s(_vm.attachmentNum) + " 张"),
                    ]),
                  ])
                : _c(
                    "div",
                    [
                      _c("span", [_vm._v("附单据")]),
                      _c("el-input", {
                        attrs: { type: "number", placeholder: "请输入" },
                        model: {
                          value: _vm.attachmentNum,
                          callback: function ($$v) {
                            _vm.attachmentNum = $$v
                          },
                          expression: "attachmentNum",
                        },
                      }),
                      _c("span", { staticClass: "suffix" }, [_vm._v("张")]),
                    ],
                    1
                  ),
            ]
          ),
          _c("div", { staticClass: "bottom-row" }, [
            _c(
              "div",
              { staticClass: "col-left" },
              [
                _c("span", [_vm._v("附件与图片")]),
                _vm.operationType !== 2
                  ? _c(
                      "el-upload",
                      {
                        staticClass: "foot-upload",
                        attrs: {
                          multiple: "",
                          accept: _vm.upextStr,
                          data: _vm.uploadParams,
                          name: "storage_file",
                          action: _vm.urlParam.server,
                          "on-success": _vm.handleUpload,
                          "show-file-list": false,
                        },
                      },
                      [
                        _c("en-icon", {
                          attrs: {
                            name: "tianjia-danchuang",
                            color: "#4894DF",
                            size: "14",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-right" },
              [
                _c("en-icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCashBtn,
                      expression: "showCashBtn",
                    },
                  ],
                  attrs: {
                    name: "jiezhuanjinetongji",
                    color: "#24C494",
                    size: "normal",
                    title: "现金流量项目",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.setCashFlowItem.apply(null, arguments)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "file-row" }, [
            _c(
              "div",
              { staticClass: "img-lis" },
              _vm._l(_vm.imageList, function (item, index) {
                return _c("img-li", {
                  key: index,
                  attrs: {
                    attr: item,
                    index: index,
                    disable: _vm.operationType === 2,
                  },
                  on: { "img-close": _vm.imageClose },
                })
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "file-lis" },
              _vm._l(_vm.fileList, function (item, index) {
                return _c("file-li", {
                  key: index,
                  attrs: {
                    attr: item,
                    index: index,
                    disable: _vm.operationType === 2,
                  },
                  on: { "file-close": _vm.fileClose },
                })
              }),
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "btn-row" },
            _vm._l(_vm.btnList, function (item, idx) {
              return _c(
                "en-button",
                {
                  key: idx,
                  attrs: { type: item.type, title: item.name },
                  on: {
                    click: function ($event) {
                      return _vm.voucherBtnClick(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            1
          ),
          _vm.operationType === 2 && _vm.voucherIdAry.length > 0
            ? _c(
                "div",
                {
                  staticClass: "btn-switch btn-left-voc",
                  class:
                    _vm.voucherIdAry.indexOf(_vm.detailId) === 0
                      ? "disabled"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.switchVoucherDetail("prev")
                    },
                  },
                },
                [
                  _c("en-icon", {
                    attrs: { name: "tupian-zuojiantou", size: "small" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.operationType === 2 && _vm.voucherIdAry.length > 0
            ? _c(
                "div",
                {
                  staticClass: "btn-switch btn-right-voc",
                  class:
                    _vm.voucherIdAry.indexOf(_vm.detailId) ===
                    _vm.voucherIdAry.length - 1
                      ? "disabled"
                      : "",
                  on: {
                    click: function ($event) {
                      return _vm.switchVoucherDetail("next")
                    },
                  },
                },
                [
                  _c("en-icon", {
                    attrs: { name: "tupian-youjiantou", size: "small" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("create-model", {
        ref: "popCreateModel",
        attrs: {
          itemList: _vm.modelItemList,
          yearNum: _vm.vcHeader.yearNum,
          accountId: _vm.vcHeader.accountId,
        },
      }),
      _c("quote-model", {
        ref: "popQuoteModel",
        attrs: {
          accountId: _vm.vcHeader.accountId,
          yearNum: _vm.vcHeader.yearNum,
        },
        on: { handleChooseModel: _vm.handleQuoteModel },
      }),
      _c("print-alert", { ref: "popPrint" }),
      _c("check-cash-flow", {
        ref: "cashFlowMod",
        attrs: {
          itemList: _vm.submitParam.itemList,
          operationType: _vm.operationType,
        },
        on: { handleCashFlowSubmit: _vm.cashFlowSubmit },
      }),
      _c("log-alert", { ref: "popLog", attrs: { logParam: _vm.logParam } }),
      _c("business-grid", {
        ref: "popBusGrid",
        attrs: { busTemplateId: _vm.busTemplateId, sourceId: _vm.sourceId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }