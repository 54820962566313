<!--
 * @Author: your name
 * @Date: 2020-03-20 16:20:46
 * @LastEditTime: 2020-07-06 10:17:15
 * @LastEditors: pengyu
 * @Description: 图片块块
 * @FilePath: \user-intelligent-accounting\src\views\voucherAdd\components\imgLi.vue
 -->
<template>
  <div class="img-li">
    <en-image
      :src="attr.src"
      :alt="attr.name"
      :preview-src-list="imgList"
    ></en-image>
    <en-icon
      v-if="!disable"
      name="shanchu-liebiao"
      size="small"
      @click.native.stop="closeClick"
    ></en-icon>
  </div>
</template>

<script>
export default {
  name: "ImgLi",
  props: {
    attr: {
      type: Object,
      default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return { imgList: [] };
  },
  mounted() {
    this.imgList.push(this.attr.src);
  },
  methods: {
    closeClick() {
      this.$emit("img-close", this.index);
    }
  }
};
</script>

 <style lang="scss" scoped>
.img-li {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  position: relative;
  img {
    border-radius: inherit;
  }
  &:hover {
    .en-icon {
      display: block;
    }
  }
  .en-icon {
    display: none;
    position: absolute;
    top: -6px;
    right: -6px;
  }
}
</style>
