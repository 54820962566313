<!--
 * @Author: pengyu
 * @Date: 2020-03-17 10:50:11
 * @LastEditTime: 2021-09-17 15:11:23
 * @LastEditors: zhulin
 * @Description: 凭证处理 --打印设置
 * @FilePath: \user\src\views\intelligentAccounting\components\printSetting\index.vue
 -->
<template>
  <en-dialog
    :visible="visible"
    title="打印"
    class="print-alert"
    width="700px"
    :modal-append-to-body="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="visible=false"
  >
    <div slot="footer">
      <en-button @click="submit">
        确定
      </en-button>
    </div>
    <el-form ref="pForm" :model="form" label-width="80px">
      <el-form-item label="打印选项" prop="checkList">
          <el-row>
            <el-checkbox label="打印制单人" v-model="form.isMakeName" :true-label="0" :false-label="1"></el-checkbox>
            <el-checkbox label="打印审核人" v-model="form.isApproveName" :true-label="0" :false-label="1"></el-checkbox>
          </el-row>
      </el-form-item>
      <el-form-item label="打印模板" prop="printModel">
        <en-select
          v-model="form.printModel"
          :data="modelList"
        >
        </en-select>
      </el-form-item>
      <el-form-item label="业务单据" prop="printBussiness">
        <en-select
          v-model="form.printBussiness"
          :data="printList"
        >
        </en-select>
      </el-form-item>
    </el-form>
  </en-dialog>
</template>

<script>
import { printService } from "@/api";
import formatTemplate from "@/components/en-print/mixin/formatTemplate.js";

export default {
  name: "PrintAlert",
  mixins: [formatTemplate],
  data() {
    return {
      visible: false,
      form: {
        isMakeName: 0,
        isApproveName: 0,
        printModel: 2,
        printBussiness: 1
      },
      modelList: [
        { value: 1, label: "发票版14*24cm" },
        { value: 2, label: "A4两版" },
        { value: 3, label: "A4三版" }
      ],
      printList: [
        { value: 1, label: "不打印" },
        { value: 2, label: "打印" }
      ],
      voucherList: []
    };
  },
  watch: {
    visible(val) {
      this.printModel = 2;
      if (val) {
        this.form = { ...this.$options.data().form };
      }
    }
  },
  methods: {
    async submit() {
      this.voucherList.checkTempalte = null;
      this.voucherList.businessData = null;
      this.visible = false;
      if (this.form.printBussiness === 2) {
        const newMatchInfo = await Promise.all(this.voucherList.map(async(item) => {
          if (item.busTemplateId) {
            const { busTemplateId, refId } = item;
            item.checkTempalte = null;
            item.businessData = null;
            await this.queryPrintPower(busTemplateId).then(async(result) => {
              if (result) {
                item.checkTempalte = result;
                try {
                  await this.queryTemplateDetail(busTemplateId, refId, item.checkTempalte?.id, item.checkTempalte?.styleType).then((p) => {
                    item.businessData = p;
                  });
                } catch (e) {
                  console.log(e);
                }
              }
            });
          }
          return item;
        }));
        const { checkTempalte, businessData } = newMatchInfo;
        this.voucherList.checkTempalte = checkTempalte;
        this.voucherList.businessData = businessData;
      }
      this.$eventHub.$emit("beginPrint", {
        voucherList: this.voucherList,
        isMakeName: this.form.isMakeName,
        isApproveName: this.form.isApproveName,
        layout: this.form.printModel,
        printBussiness: this.form.printBussiness
      });
    },
    // 打印之前查询打印模板 有返回值就是有权限
    async queryPrintPower(id) {
      let powers = null;
      await printService.queryPrintTemplate({
        templateId: id
      }).then((result) => {
        if (result[0].enableFlag === 0) {
          powers = result[0];
        }
      });
      return powers;
    },
    // 查询业务建模数据
    async queryTemplateDetail(busTemplateId, refId, printId, styleType) {
      const result = await printService.queryPrintDetail({
        templateId: busTemplateId,
        printId,
        id: refId,
        styleType
      });
      return { ...this.formatData(result) };
    }
  }
};
</script>

<style lang="scss" scoped>
.print-alert{
  & /deep/ .el-dialog__header{
    text-align: left;
  }
  & /deep/ .el-checkbox-group{
    text-align: left;
  }
  & /deep/ .el-form{
    height: 300px;
  }
  .en-select{
    width:100%;
  }
}
</style>
