var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "status-model",
      style: { backgroundColor: _vm.statusBgColor(), color: _vm.statusColor() },
    },
    [_vm._v(" " + _vm._s(_vm.statusName()) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }