/*
 * @Author: pengyu
 * @Date: 2020-03-17 17:00:31
 * @LastEditTime: 2021-06-24 17:06:15
 * @LastEditors: pengyu
 * @Description: 根据凭证状态数据--获取凭证的 单行按钮
 * @FilePath: \user-intelligent-accounting\src\mixins\getBtnsByStatus.js
 */
export default {
  methods: {
    /**
     * @description: 获取分录行|列表单行按钮
     * @param rowData 行数据
     * @param comm 管理端配置项
     * @param isCheckPage 是否是凭证审核页面
     * @return:
     */
    getSingleBtnList(rowData, comm, isCheckPage) {
      const btns = [
        {
          id: "edit",
          name: "修改",
          icon: "bianji-liebiao",
          iconColor: "#49bef2"
        },
        {
          id: "delete",
          name: "删除",
          icon: "shanchu-liebiao"
        },
        {
          id: "check",
          name: "审核",
          icon: "shenhe1",
          iconColor: "#49bef2"
        },
        {
          id: "cancelCheck",
          name: "取消审核",
          icon: "quxiaoshenhe",
          iconColor: "#f54646"
        },
        {
          id: "charge",
          name: "冲销",
          icon: "baoxiao-liebiao",
          iconColor: "#26C494"
        },
        {
          id: "print",
          name: "打印",
          icon: "kaipiao-liebiao",
          iconColor: "#26C494"
        },
        {
          id: "log",
          name: "日志",
          icon: "rizhi-liebiao",
          iconColor: "#26C494"
        },
        {
          id: "copy",
          name: "复制",
          icon: "fuzhi-kuaijiecaozuo",
          iconColor: "#26C494"
        },
        {
          id: "submit",
          name: "提交",
          icon: "fabu-yuanxing",
          iconColor: "#F6BE29"
        }
      ];
      const { userInfo } = this.$store.getters;
      /**
       * @description: 删除指定按钮
       * @param arr
       * @param id
       * @return:
       */
      function remove(arr, ids) {
        ids.forEach((itm) => {
          const idx = arr.findIndex((a) => a.id === itm);
          if (idx > -1) {
            arr.splice(idx, 1);
          }
        });
      }

      // 根据 状态 管理端配置 筛选所需按钮
      const {
        status, isCloseAccount, isChangeAccount, voucherWay, isEdit, makeId, voucherType
      } = rowData;
      const { isSamePer, allowEditDel } = comm;
      // 系统凭证&&不允许手工修改
      const flg1 = voucherWay === 1 && isEdit === 2;
      // 手工凭证&&不允许修改、删除他人填制的凭证&&制单人不为当前用户
      const flg2 = voucherWay === 2 && allowEditDel === 1 && makeId !== userInfo.userId;
      // 制单人、审核人不为同一人&&凭证制单人为当前用户时
      const flg3 = isSamePer === 1 && makeId === userInfo.userId;
      // 冲销凭证
      const flg4 = voucherType === "001"; // 冲销凭证
      if (status === "000") {
        // 待审核
        remove(btns, ["cancelCheck", "charge", "submit"]);
        if (isCheckPage) {
          remove(btns, ["edit", "delete", "copy"]);
        } else {
          remove(btns, ["check"]);
        }
        if (flg1 || flg2 || isCloseAccount === 1 || isChangeAccount === 1) {
          remove(btns, ["edit", "delete"]);
        }
        if (flg3 || isCloseAccount === 1 || isChangeAccount === 1) {
          remove(btns, ["check"]);
        }
      } else if (status === "001") {
        // 已审核
        remove(btns, ["check", "delete", "edit", "submit"]);
        if (isCheckPage) {
          remove(btns, ["charge", "copy"]);
        } else {
          remove(btns, ["cancelCheck"]);
        }
        if (flg3 || isCloseAccount === "1") {
          remove(btns, ["cancelcheck"]);
        }
        if (isChangeAccount === "1" || flg4) {
          remove(btns, ["charge"]);
        }
      } else if (status === "002") {
        // 异常
        remove(btns, ["check", "cancelCheck", "print", "charge", "submit"]);
        if (flg1 || flg2 || isCloseAccount === 1 || isChangeAccount === 1) {
          remove(btns, ["edit", "delete"]);
        }
      } else if (status === "004") {
        // 未提交
        remove(btns, ["check", "cancelCheck", "print", "charge"]);
        if (flg1 || flg2 || isCloseAccount === 1 || isChangeAccount === 1) {
          remove(btns, ["edit", "delete"]);
        }
      }

      // 会计期间年度内还有未结账的  非冲销凭证   凭证审核页面无复制
      if (isChangeAccount !== 2 || flg4 || isCheckPage) {
        remove(btns, ["copy"]);
      }

      return btns;
    },
    /**
     * @description: 凭证详情页根据状态获取按钮
     * @param action 新增1|查看2|编辑3|复制4|冲销5 页面
     * @param data 凭证数据
     * @param isCheckPage 是否是凭证审核页面
     * @return:
     */
    getDetailBtnList(action, data, isCheckPage) {
      const btns = [
        {
          id: "edit",
          name: "修改",
          type: "white"
        },
        {
          id: "delete",
          name: "删除",
          type: "red"
        },
        {
          id: "log",
          name: "日志",
          type: "white"
        },
        {
          id: "print",
          name: "打印",
          type: "white"
        },
        {
          id: "save",
          name: "暂存",
          type: "white"
        },
        {
          id: "continueAdd",
          name: "继续添加",
          type: "green"
        },
        {
          id: "submit",
          name: "提交",
          type: "blue"
        },
        {
          id: "check",
          name: "审核",
          type: "white"
        },
        {
          id: "cancelCheck",
          name: "取消审核",
          type: "white"
        },
        {
          id: "copy",
          name: "复制",
          type: "white"
        },
        {
          id: "charge",
          name: "冲销",
          type: "blue"
        }
      ];
      const { userInfo } = this.$store.getters;
      /**
       * @description: 删除指定按钮
       * @param arr
       * @param id
       * @return:
       */
      function remove(arr, ids) {
        ids.forEach((itm) => {
          const idx = arr.findIndex((a) => a.id === itm);
          if (idx > -1) {
            arr.splice(idx, 1);
          }
        });
      }

      if (action === 1 || action === 4) {
        // 新增  复制
        remove(btns, ["edit", "delete", "log", "print", "check", "cancelCheck", "copy", "charge"]);
        return btns;
      } if (action === 3) {
        remove(btns, ["edit"]);
      }
      // 凭证新增页面才有继续添加4.9.4
      if (action !== 1) {
        remove(btns, ["continueAdd"]);
      }

      const {
        status, voucherWay, isEdit, allowEditDel, makeId, isSamePer, voucherType, isChangeAccount
      } = data;
      // 系统凭证&&不允许手工修改
      const flg1 = voucherWay === 1 && isEdit === 2;
      // 手工凭证&&不允许修改、删除他人填制的凭证&&制单人不为当前用户
      const flg2 = voucherWay === 2 && allowEditDel === 1 && makeId !== userInfo.userId;
      // 制单人、审核人不允许为同一人&&凭证制单人为当前用户时
      const flg3 = isSamePer === 1 && makeId === userInfo.userId;
      // 冲销凭证
      const flg4 = voucherType === "001"; // 冲销凭证

      if (status === "000") {
        // 待审核
        if (isCheckPage) {
          remove(btns, ["copy", "edit", "delete", "cancelCheck", "submit", "save", "continueAdd", "charge"]);
        } else {
          // 凭证处理页面无审核按钮
          /* eslint-disable */
          if (action === 2) {
            // 查看
            remove(btns, ["check","cancelCheck","save","continueAdd","submit","charge"]);
          } else {
            // 编辑 复制 冲销
            remove(btns, ["edit","check","cancelCheck","copy","charge"]);
          }
          /* eslint-enable */
        }
        if (flg1 || flg2) {
          remove(btns, ["edit", "delete"]);
        }
        if (flg3) {
          remove(btns, ["check"]); // 无审核按钮
        }
        // 待审核 去除暂存 v4.9.10
        remove(btns, ["save"]);
      } else if (status === "001") {
        // 已审核
        if (isCheckPage) {
          remove(btns, ["edit", "delete", "copy", "save", "submit", "continueAdd", "charge", "check"]);
        } else if (action === 2) { // 查看
          remove(btns, ["edit", "delete", "check", "cancelCheck", "save", "continueAdd", "submit"]);
        } else if (action === 3 || action === 4) {
          remove(btns, ["edit", "delete", "check", "cancelCheck", "save", "continueAdd", "submit"]);
        } else if (action === 5) {
          remove(btns, ["edit", "delete", "copy", "check", "cancelCheck", "charge", "continueAdd"]);
        } else {
          remove(btns, ["edit", "delete", "check", "cancelCheck", "save", "continueAdd", "submit"]);
        }
        if (flg3) {
          remove(btns, ["edit"]);
        }
      } else if (status === "002") {
        remove(btns, ["check", "cancelCheck", "charge", "print"]);
        // 异常
        if (flg1 || flg2) {
          remove(btns, ["edit", "delete"]);
        }
      } else if (status === "004") {
        // 未提交
        remove(btns, ["charge", "print"]);

        if (!isCheckPage) {
          remove(btns, ["check", "cancelCheck"]);
        }
        if (action === 2) {
          remove(btns, ["save", "continueAdd", "submit", "change", "print"]);
        }
        if (action === 3) {
          remove(btns, ["copy"]);
        }
        if (flg1 || flg2) {
          remove(btns, ["edit", "delete"]);
        }
      }

      // 凭证审核页面无复制
      if (isCheckPage || isChangeAccount !== 2) {
        remove(btns, ["copy"]);
      }
      if (flg4) {
        remove(btns, ["charge", "copy"]);
      }
      return btns;
    }
  }
};
