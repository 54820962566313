<!--
 * @Author: pengyu
 * @Date: 2020-03-13 18:10:45
 * @LastEditTime: 2020-12-26 13:36:53
 * @LastEditors: pengyu
 * @Description: 凭证状态-显示
 * @FilePath: \user-intelligent-accounting\src\components\statusModel\index.vue
 -->
<template>
  <div
    class="status-model"
    :style="{backgroundColor:statusBgColor(), color: statusColor()}"
  >
    {{ statusName() }}
  </div>
</template>

<script>
export default {
  name: "StatusModel",
  props: {
    status: {
      type: String,
      default: ""
    }
  },
  methods: {
    /**
     * @description: 背景色
     * @param {status}
     * @return:
     */
    statusBgColor() {
      switch (this.status) {
        case "000":
        case "004":
          return "#E8F2FACC";
        case "001":
          return "#26C3931A";
        case "002":
          return "#F6EBEA";
        default:
          return "#E8F2FACC";
      }
    },
    /**
     * @description: 字体颜色
     * @param {status}
     * @return:
     */
    statusColor() {
      switch (this.status) {
        case "000":
        case "004":
          return "#3e90fe";
        case "001":
          return "#26C393";
        case "002":
          return "#F66A69";
        default:
          return "#3e90fe";
      }
    },
    /**
     * @description: 状态名称
     * @param {status}
     * @return:
     */
    statusName() {
      switch (this.status) {
        case "000":
          return "待审核";
        case "001":
          return "已审核";
        case "002":
          return "生成异常";
        case "004":
          return "未提交";
        default:
          return "未提交";
      }
    }
  }

};
</script>

<style lang="scss" scoped>
.status-model {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  border-radius: 3px;
  width: 65px;
  text-align: center;
  font-weight: 500;
}
</style>
