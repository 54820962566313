<!--
 * @Author: pengyu
 * @Date: 2020-07-06 16:08:08
 * @LastEditors: pengyu
 * @LastEditTime: 2021-01-21 14:03:54
 * @Description: 现金流量项目设置 - 借贷平衡设置
-->
<template>
  <en-dialog
    :visible="visible"
    title="现金流量项目"
    class="cash-flow-alert"
    width="1100px"
    :modal-append-to-body="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="visible=false"
  >
    <div slot="footer">
      <en-button v-if="operationType!==2" @click="submit">
        确定
      </en-button>
    </div>
    <en-table
      :data="tableData"
      height="460px"
      :loading="loading"
    >
      <en-table-column type="index" label="序号" width="60" align="center"></en-table-column>
      <en-table-column
        prop="remark"
        label="摘要"
      >
      </en-table-column>
      <en-table-column
        label="科目"
      >
        <template v-slot:edit="{row}">
          <div class="self-td">{{`${row.subjectCode}  ${row.subjectName}`}}</div>
        </template>
      </en-table-column>
      <en-table-column
        label="方向"
        width="90"
      >
        <template v-slot:edit="{row}">
          <div class="self-td">{{row.amountC ? "贷" : "借" }}</div>
        </template>
      </en-table-column>
      <en-table-column
        label="金额"
        width="120"
        align="right"
      >
        <template v-slot:edit="{row}">
          <div class="self-td">{{(row.amountC || row.amountD) | thousand }}</div>
        </template>
      </en-table-column>
      <en-table-column
        label="现金流量项目"
      >
        <template v-slot:edit="{row}">
          <div class="is-edit">
            <en-select
              v-if="row.amountC"
              :value="row.cashFlowItem || row.cCashPro"
              :data="subjectList"
              placeholder="请选择"
              filterable
              :props="{value:'id',label: labelFormat}"
              @change="(val)=>handleSubjectChange(val,row)"
            >
            </en-select>
            <en-select
              v-else-if="row.amountD"
              :value="row.cashFlowItem || row.dCashPro"
              :data="subjectList"
              placeholder="请选择"
              filterable
              :props="{value:'id',label: labelFormat}"
              @change="(val)=>handleSubjectChange(val,row)"
            >
            </en-select>
          </div>
        </template>
      </en-table-column>
    </en-table>
  </en-dialog>
</template>

<script>
import { thousand } from "en-js";
import { cloneDeep } from "lodash";
import { voucherDetail } from "@/api";

export default {
  name: "CheckCashFlow",
  props: {
    itemList: { // 分录行数据
      type: Array,
      default: () => []
    },
    operationType: Number
  },
  filters: {
    thousand(value, decimal = 2) {
      return thousand(Number(value), decimal);
    }
  },
  watch: {
    visible(nVal) {
      if (nVal) {
        this.$nextTick(() => {
          this.queryCashFlowObject();
        });
      } else {
        this.tableData = [];
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      tableData: [],
      subjectList: [] // 现金流量科目
    };
  },
  methods: {
    /**
     * @description: 查询现金流量科目
     */
    async queryCashFlowObject() {
      try {
        this.loading = true;
        const rsp = await voucherDetail.queryObjectTree({ objType: "017", pageNo: 1, pageSize: 9999 });
        this.loading = false;
        // 按科目code 排序
        this.subjectList = rsp.sort((a, b) => Number(a.code) - Number(b.code));
        this.tableData = cloneDeep(this.itemList.filter((itm) => (itm.subjectId && Number(itm.cashSubject) !== 1)));
      } catch (error) {
        this.loading = false;
      }
    },
    /**
     * @description: 现金流量科目选择回调
     * @param val 选项值
     * @param row 行数据
     */
    handleSubjectChange(val, row) {
      this.$set(row, "cashFlowItem", "");
      this.$set(row, "cashFlowItemName", "");
      if (row.amountC) {
        this.$set(row, "cCashPro", val);
        this.$set(row, "dCashPro", "");
      } else if (row.amountD) {
        this.$set(row, "cCashPro", "");
        this.$set(row, "dCashPro", val);
      }
    },
    labelFormat(data) {
      return `${data.code || ""} ${data.name || ""}`;
    },
    /**
     * @description: 确定
     */
    submit() {
      this.visible = false;
      this.$emit("handleCashFlowSubmit", this.tableData);
    }
  }
};
</script>

<style lang='scss' scoped>
.cash-flow-alert{
  & /deep/ .el-dialog__header{
    text-align: left;
  }
  .self-td{
    padding: 0 12px;
  }
  & /deep/ .vxe-table--repair{
    height: 0;
  }
}

</style>
