var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-dialog",
    {
      staticClass: "business-grid",
      attrs: {
        visible: _vm.visible,
        title: "业务单据",
        width: "1100",
        "append-to-body": "",
      },
      on: {
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "en-table",
        {
          staticClass: "table",
          attrs: {
            loading: _vm.loading,
            data: _vm.tableData,
            "page-config": {
              pageModel: _vm.pageModel,
              changeMethod: _vm.handlePageChanged,
            },
            "column-min-width": "120",
            height: "400px",
          },
          on: {
            "row-click": _vm.handleRowClick,
            "filter-sort-change": _vm.handleFilterSortChange,
          },
        },
        _vm._l(_vm.columnData, function (col, index) {
          return _c("table-column", {
            key: col.id || index,
            attrs: { data: col, isHomePage: true },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }