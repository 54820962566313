<!--
 * @Author: your name
 * @Date: 2020-03-20 16:38:04
 * @LastEditTime: 2020-07-06 10:17:46
 * @LastEditors: pengyu
 * @Description: 文件块块
 * @FilePath: \user-intelligent-accounting\src\views\voucherAdd\components\fileLi.vue
 -->
<template>
  <div
    class="file-li"
    @click.stop="download"
  >
    <en-icon
      :name="fileIcon[attr.extension].icon"
      :color="fileIcon[attr.extension].color"
      size="small"
      class="icon"
    ></en-icon>
    <span class="name">{{ attr.name }}</span>
    <en-icon
      v-if="!disable"
      class="close-btn"
      name="shanchu-liebiao"
      size="small"
      @click.native.stop="closeClick"
    ></en-icon>
  </div>
</template>

<script>
export default {
  name: "FileLi",
  props: {
    attr: {
      type: Object,
      default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileIcon: {
        txt: { icon: "fujian-txt", color: "#21A3E6" },
        xls: { icon: "fujian-excel", color: "#4AAE2D" },
        xlsx: { icon: "fujian-excel", color: "#4AAE2D" },
        docx: { icon: "fujian-Word", color: "#537CE7" },
        doc: { icon: "fujian-Word", color: "#537CE7" },
        ppt: { icon: "fujian-PPT", color: "#FB7A26" },
        pdf: { icon: "fujian-pdf", color: "#EC1C32" }
      }
    };
  },
  methods: {
    /**
     * @description: 关闭
     */
    closeClick() {
      this.$emit("file-close", this.index);
    },
    /**
   * @description: 点击下载
   */
    download() {
      if (this.attr.src) {
        window.open(this.attr.src);
      }
    }
  }
};
</script>

 <style lang="scss" scoped>
.file-li {
  width: 450px;
  height: 36px;
  border: 1px solid #edf1f6;
  background: #f6f7fb;
  border-radius: 5px;
  position: relative;
  line-height: 36px;
  text-align: left;
  cursor: pointer;
  &:hover {
    .close-btn {
      display: inline-block;
    }
  }
  .icon {
    width: 10%;
    vertical-align: middle;
  }
  .name {
    width: 80%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    vertical-align: middle;
  }
  .close-btn {
    position: absolute;
    top: 9px;
    right: 10px;
    display: none;
  }
}
</style>
