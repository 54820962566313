var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "en-table",
    {
      ref: "voucherTable",
      staticClass: "voucher-table",
      attrs: {
        autoResize: true,
        stripe: false,
        data: _vm.itemList,
        "edit-config": {},
        "cell-class-name": _vm.cellClassName,
        "span-method": _vm.spanMethod,
        "max-height": "280",
        "show-overflow": "",
      },
      on: { "cell-click": _vm.handleCellClick },
      scopedSlots: _vm._u([
        {
          key: "hoverRow",
          fn: function ({ row, rowIndex }) {
            return [
              rowIndex !== _vm.itemList.length - 1
                ? _c("en-expand-buttons", {
                    attrs: { data: _vm.btnList, "min-number": 1 },
                    on: {
                      "button-click": function ($event) {
                        return _vm.handleRowBtnClick(
                          row,
                          rowIndex,
                          arguments[1],
                          arguments[0]
                        )
                      },
                    },
                  })
                : _vm._e(),
            ]
          },
        },
      ]),
    },
    [
      _c("en-table-column", {
        attrs: {
          title: "序号",
          width: "60",
          align: "center",
          "column-key": "id",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.rowIndex === _vm.itemList.length - 1
                  ? _c("span", [_vm._v("合计: " + _vm._s(_vm.getTotal))])
                  : _c("span", [_vm._v(_vm._s(scope.rowIndex + 1))]),
              ]
            },
          },
        ]),
      }),
      _c("en-table-column", {
        attrs: { field: "remark", title: "摘要" },
        scopedSlots: _vm._u([
          {
            key: "edit",
            fn: function ({ row, rowIndex }) {
              return [
                rowIndex === _vm.itemList.length - 1
                  ? _c("span", [_vm._v(_vm._s(_vm._f("thousand")(_vm.totalD)))])
                  : _c(
                      "el-popover",
                      {
                        staticClass: "zy-alert",
                        attrs: {
                          placement: "bottom-start",
                          width: "400",
                          trigger: "click",
                          "popper-class": "zhaiyao-alert",
                          disabled:
                            _vm.actionType === 2 || _vm.actionType === 5,
                          "visible-arrow": false,
                        },
                      },
                      [
                        row.remarkList
                          ? _c(
                              "div",
                              { staticClass: "zy-wrap" },
                              _vm._l(row.remarkList, function (itm, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: idx,
                                    staticClass: "node-temps",
                                    class:
                                      row.remark === itm.remark ? "cur" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemarkClick(
                                          itm.remark,
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(itm.remark) + " ")]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { attrs: { slot: "reference" }, slot: "reference" },
                          [
                            _c("el-input", {
                              class:
                                _vm.actionType === 5 ||
                                _vm.voucherType === "001"
                                  ? "clr-negative"
                                  : "",
                              attrs: {
                                disabled: Number(_vm.actionType) === 2,
                                placeholder: "请输入",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getPrevRemark(row, rowIndex)
                                },
                                input: (val) => _vm.getSearchRemark(val, row),
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  return _vm.handleZyKeydown($event)
                                },
                              },
                              model: {
                                value: row.remark,
                                callback: function ($$v) {
                                  _vm.$set(row, "remark", $$v)
                                },
                                expression: "row.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
              ]
            },
          },
        ]),
      }),
      _c("en-table-column", {
        attrs: {
          field: "subjectId",
          title: "科目",
          "show-overflow-tooltip": true,
        },
        scopedSlots: _vm._u([
          {
            key: "edit",
            fn: function ({ row, rowIndex }) {
              return [
                rowIndex === _vm.itemList.length - 1
                  ? _c("span", [_vm._v(_vm._s(_vm._f("thousand")(_vm.totalC)))])
                  : _vm.actionType === 2 || _vm.actionType === 5
                  ? _c(
                      "div",
                      {
                        class:
                          _vm.actionType === 5 || _vm.voucherType === "001"
                            ? "clr-negative"
                            : "",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(`${row.subjectCode} ${row.subjectName}`) +
                            " "
                        ),
                      ]
                    )
                  : _c("en-select", {
                      attrs: {
                        filterable: "",
                        data: _vm.subjectList,
                        props: _vm.subjectProps,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.handleSubjectSelect(row)
                        },
                        "visible-change": _vm.closePopover,
                      },
                      model: {
                        value: row.subjectId,
                        callback: function ($$v) {
                          _vm.$set(row, "subjectId", $$v)
                        },
                        expression: "row.subjectId",
                      },
                    }),
              ]
            },
          },
        ]),
      }),
      _c("en-table-column", {
        attrs: {
          field: "fzhs",
          title: "辅助核算",
          "show-overflow-tooltip": false,
        },
        scopedSlots: _vm._u([
          {
            key: "edit",
            fn: function ({ row, rowIndex }) {
              return [
                _c(
                  "el-popover",
                  {
                    staticClass: "fzhs-alert",
                    attrs: {
                      placement: "bottom-start",
                      width: "360",
                      trigger: "click",
                      disabled:
                        _vm.actionType === 2 ||
                        _vm.actionType === 5 ||
                        !row.assitAcc,
                    },
                    on: {
                      show: function ($event) {
                        return _vm.popoverFzhsShow(row)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "fzhs-wrap",
                        attrs: { id: `fzhs${rowIndex}` },
                      },
                      _vm._l(row.fzhsbd, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "flex-item" },
                          [
                            _c("div", { staticClass: "label" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "selection" },
                              [
                                _c("en-select-data", {
                                  attrs: {
                                    config: _vm.getFzhsConfig(item),
                                    modelValue: item.id,
                                  },
                                  model: {
                                    value: _vm.fzhsForm[item.id],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.fzhsForm, item.id, $$v)
                                    },
                                    expression: "fzhsForm[item.id]",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { style: { "text-align": "right" } },
                      [
                        _c(
                          "en-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.setFzhsCell(row)
                              },
                            },
                          },
                          [_vm._v(" 确认 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: { padding: "10px" },
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      _vm._l(row.fzhs, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            class: _vm.actionType === 5 ? "clr-negative" : "",
                            on: {
                              contextmenu: function ($event) {
                                $event.preventDefault()
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(item.keyName) +
                                "：" +
                                _vm._s(item.valueName || "") +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("en-table-column", {
        attrs: { field: "slwb", title: "数量/外币" },
        scopedSlots: _vm._u([
          {
            key: "edit",
            fn: function ({ row, rowIndex }) {
              return [
                _c(
                  "el-popover",
                  {
                    staticClass: "slwb-alert",
                    attrs: {
                      placement: "bottom-start",
                      width: "360",
                      trigger: "click",
                      disabled:
                        _vm.actionType === 2 ||
                        _vm.actionType === 5 ||
                        (!row.quantityUnit && !row.forcoinAcc),
                    },
                    on: {
                      show: function ($event) {
                        return _vm.popoverSlwbShow(row, rowIndex)
                      },
                    },
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "slwbForm",
                        attrs: { model: _vm.countForm, "label-width": "40px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.quantityUnit,
                                expression: "row.quantityUnit",
                              },
                            ],
                            attrs: { label: "数量", prop: "quantityAcc" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              on: {
                                input: (val) => {
                                  _vm.countFormChange(val, "quantityAcc")
                                },
                              },
                              model: {
                                value: _vm.countForm.quantityAcc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.countForm, "quantityAcc", $$v)
                                },
                                expression: "countForm.quantityAcc",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.quantityUnit,
                                expression: "row.quantityUnit",
                              },
                            ],
                            staticClass: "foro-row",
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "单价", prop: "price" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入" },
                                  on: {
                                    input: (val) => {
                                      _vm.countFormChange(val, "price")
                                    },
                                  },
                                  model: {
                                    value: _vm.countForm.price,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.countForm, "price", $$v)
                                    },
                                    expression: "countForm.price",
                                  },
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.countForm.foroCurrSys)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.forcoinAcc,
                                expression: "row.forcoinAcc",
                              },
                            ],
                            staticClass: "third-row",
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "原币", prop: "foroCurrSys" } },
                              [
                                _c("en-select", {
                                  attrs: {
                                    placeholder: "请选择",
                                    data: row.foroCurrSysList || [],
                                    props: { value: "value", label: "text" },
                                  },
                                  on: { change: _vm.getMonthRate },
                                  model: {
                                    value: _vm.countForm.foroCurrSys,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.countForm,
                                        "foroCurrSys",
                                        $$v
                                      )
                                    },
                                    expression: "countForm.foroCurrSys",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "", prop: "amount" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "金额" },
                                  on: {
                                    input: (val) => {
                                      _vm.countFormChange(val, "amount")
                                    },
                                  },
                                  model: {
                                    value: _vm.countForm.amount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.countForm, "amount", $$v)
                                    },
                                    expression: "countForm.amount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "汇率", prop: "rate" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.countForm.rate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.countForm, "rate", $$v)
                                },
                                expression: "countForm.rate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { style: { "text-align": "right" } },
                      [
                        _c(
                          "en-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.setCountCell(row)
                              },
                            },
                          },
                          [_vm._v(" 确认 ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: _vm.actionType === 5 ? "clr-negative" : "",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row && Number(row.quantityAcc || 0),
                                expression:
                                  "row && Number(row.quantityAcc || 0)",
                              },
                            ],
                          },
                          [_vm._v(" 数量：" + _vm._s(row.quantityAcc) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row && Number(row.price || 0),
                                expression: "row && Number(row.price || 0)",
                              },
                            ],
                          },
                          [_vm._v(" 单价：" + _vm._s(row.price) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row && row.foroCurrSys && row.amount,
                                expression:
                                  "row && row.foroCurrSys && (row.amount)",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(row.foroCurrSys) +
                                "：" +
                                _vm._s(row.amount) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row && Number(row.rate || 0),
                                expression: "row && Number(row.rate || 0)",
                              },
                            ],
                          },
                          [_vm._v(" 汇率：" + _vm._s(row.rate) + " ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.showWb
        ? _c(
            "en-table-column",
            {
              key: Math.random(),
              attrs: { field: "amountD", title: "借方金额" },
            },
            [
              _c("en-table-column", {
                attrs: { field: "foroCurrAmountD", title: "原币" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "edit",
                      fn: function ({ row }) {
                        return [
                          row.foroCurrAmountD
                            ? _c(
                                "div",
                                {
                                  class:
                                    Number(row.amountD) < 0
                                      ? "clr-negative"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getAmountShowValue(
                                          row.foroCurrAmountD
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3813872486
                ),
              }),
              _c("en-table-column", {
                attrs: { field: "baseD", title: "本位币" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "edit",
                      fn: function ({ row }) {
                        return [
                          row.amountD
                            ? _c(
                                "div",
                                {
                                  class:
                                    Number(row.amountD) < 0
                                      ? "clr-negative"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getAmountShowValue(row.amountD)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3077510566
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.showWb
        ? _c("en-table-column", {
            key: Math.random(),
            attrs: { field: "amountD", title: "借方金额" },
            scopedSlots: _vm._u(
              [
                {
                  key: "edit",
                  fn: function ({ row, rowIndex }) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: row.amountD && !row.isEditamountD,
                              expression: "row.amountD && !row.isEditamountD",
                            },
                          ],
                          staticClass: "bmoney",
                          class:
                            Number(row.amountD) < 0 ||
                            _vm.actionType === 5 ||
                            _vm.voucherType === "001"
                              ? "clr-negative"
                              : "",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getAmountShowValue(row.amountD)) +
                              " "
                          ),
                        ]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: row.isEditamountD,
                            expression: "row.isEditamountD",
                          },
                        ],
                        class: Number(row.amountD) < 0 ? "clr-negative" : "",
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.actionType === 2,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.amountChange(row, "amountD")
                          },
                          blur: function ($event) {
                            row.isEditamountD = null
                          },
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            return _vm.handleKeyCode(
                              $event,
                              row,
                              rowIndex,
                              "amountD"
                            )
                          },
                        },
                        model: {
                          value: row.amountD,
                          callback: function ($$v) {
                            _vm.$set(row, "amountD", $$v)
                          },
                          expression: "row.amountD",
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              1313603477
            ),
          })
        : _vm._e(),
      _vm.showWb
        ? _c(
            "en-table-column",
            {
              key: Math.random(),
              attrs: { field: "amountC", title: "贷方金额" },
            },
            [
              _c("en-table-column", {
                attrs: { field: "foroCurrAmountC", title: "原币" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "edit",
                      fn: function ({ row }) {
                        return [
                          row.foroCurrAmountC
                            ? _c(
                                "div",
                                {
                                  class:
                                    Number(row.amountC) < 0
                                      ? "clr-negative"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getAmountShowValue(
                                          row.foroCurrAmountC
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2002679169
                ),
              }),
              _c("en-table-column", {
                attrs: { field: "baseC", title: "本位币" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "edit",
                      fn: function ({ row }) {
                        return [
                          row.amountC
                            ? _c(
                                "div",
                                {
                                  class:
                                    Number(row.amountC) < 0
                                      ? "clr-negative"
                                      : "",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getAmountShowValue(row.amountC)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  558802625
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.showWb
        ? _c("en-table-column", {
            key: Math.random(),
            attrs: { field: "amountC", title: "贷方金额" },
            scopedSlots: _vm._u(
              [
                {
                  key: "edit",
                  fn: function ({ row, rowIndex }) {
                    return [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: row.amountC && !row.isEditamountC,
                              expression: "row.amountC && !row.isEditamountC",
                            },
                          ],
                          staticClass: "dmoney",
                          class:
                            Number(row.amountC) < 0 ||
                            _vm.actionType === 5 ||
                            _vm.voucherType === "001"
                              ? "clr-negative"
                              : "",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getAmountShowValue(row.amountC)) +
                              " "
                          ),
                        ]
                      ),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: row.isEditamountC && _vm.actionType !== 2,
                            expression: "row.isEditamountC && actionType!==2",
                          },
                        ],
                        class: Number(row.amountC) < 0 ? "clr-negative" : "",
                        attrs: {
                          placeholder: "请输入",
                          disabled: _vm.actionType === 2,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.amountChange(row, "amountC")
                          },
                          blur: function ($event) {
                            row.isEditamountC = null
                          },
                        },
                        nativeOn: {
                          keydown: function ($event) {
                            return _vm.handleKeyCode(
                              $event,
                              row,
                              rowIndex,
                              "amountC"
                            )
                          },
                        },
                        model: {
                          value: row.amountC,
                          callback: function ($$v) {
                            _vm.$set(row, "amountC", $$v)
                          },
                          expression: "row.amountC",
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              1806231988
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }