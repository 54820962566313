<!--
 * @Author: pengyu
 * @Date: 2020-03-20 18:01:19
 * @LastEditTime: 2020-12-25 18:07:56
 * @LastEditors: pengyu
 * @Description: 新增凭证--引用模板
 * @FilePath: \user-intelligent-accounting\src\components\quoteModel\index.vue
 -->
<template>
  <en-dialog
    :visible="visible"
    title="引用模板"
    class="quote-alert"
    width="900px"
    :modal-append-to-body="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="visible=false"
  >
    <en-table
      :data="tableData"
      height="400px"
      @row-click="handleRowClick"
      :page-config="{pageModel: pageModel, changeMethod: handlePageChanged}"
    >
      <en-table-column type="index" width="60"></en-table-column>
      <en-table-column
        prop="templateName"
        label="模板标题"
        width="360"
      >
      </en-table-column>
      <en-table-column
        prop="totalD"
        label="借"
      >
        <template v-slot:edit="{row}">
          <div class="subject-b">{{row.itemList | getSubject("B") }}</div>
        </template>
      </en-table-column>
      <en-table-column
        prop="totalC"
        label="贷"
      >
        <template v-slot:edit="{row}">
          <div class="subject-d">{{row.itemList | getSubject("C") }}</div>
        </template>
      </en-table-column>
      <template #hoverRow="{row}">
        <en-icon name="shanchu-liebiao" size="small" @click.native="deleteModel(row)"></en-icon>
      </template>
    </en-table>
  </en-dialog>
</template>

<script>
import { MessageBox } from "element-ui";
import { voucherDetail } from "@/api";

export default {
  name: "QuoteAlert",
  props: {
    actionType: { // 当从查看凭证 调用时 可查看删除 但是不能引用
      type: Number,
      default: 0
    },
    accountId: {
      type: String,
      default: ""
    },
    yearNum: {
      type: String,
      default: ""
    }
  },
  filters: {
    // 获取借方科目
    getSubject(list, type) {
      const ary = typeof (list) === "string" ? JSON.parse(list) : list;
      const str = [];
      ary.forEach((itm) => {
        if (str.length < 2 && ((type === "B" && itm.amountD) || (type === "C" && !itm.amountD))) {
          str.push(`${itm.subjectCode} ${itm.subjectName}`);
        }
      });
      return str.join("  ");
    }
  },
  data() {
    return {
      visible: false,
      tableData: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: "",
        totalPages: ""
      }
    };
  },
  watch: {
    visible(newval) {
      if (newval) {
        this.queryModelList();
      }
    }
  },
  methods: {
    /**
     * @description: 查询列表数据
     * @param {type}
     */
    async queryModelList() {
      const pa = {
        accountId: this.accountId,
        yearNum: this.yearNum,
        pageNo: this.pageModel.pageNo,
        pageSize: this.pageModel.pageSize
      };
      const rsp = await voucherDetail.queryCollectList(pa);
      this.tableData = rsp.records;
      this.pageModel.total = rsp.total;
      this.pageModel.totalPages = rsp.totalPages;
    },
    /**
     * @description: 分页器 事件
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      // 重新查询
      this.queryModelList();
    },
    /**
     * @description: 删除一行
     */
    deleteModel(row) {
      const _this = this;
      const { id } = row;
      MessageBox.confirm("确定要删除这条收藏吗？", "", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "warning"
      }).then(async() => {
        await voucherDetail.deleteCollectVoucher({ id });
        _this.queryModelList();
      });
    },
    /**
     * @description: 表格行点击事件
     * @param row
     */
    handleRowClick({ row }) {
      const _this = this;
      if (this.actionType === 2) {
        return;
      }
      MessageBox.confirm("确定要引用这条收藏吗？", "", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "warning"
      }).then(async() => {
        const itm = typeof (row.itemList) === "string" ? JSON.parse(row.itemList) : row.itemList;
        _this.$emit("handleChooseModel", itm);
        _this.visible = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.quote-alert {
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  .subject-b,.subject-d{
    padding: 0 12px;
  }
  & /deep/ .vxe-hover-row-right-wrapper{
    padding-right: 10px;
  }
  & /deep/ .vxe-table--repair{
    height: 0;
  }
}
</style>
