<!--
 * @Author: pengyu
 * @Date: 2020-03-17 11:22:39
 * @LastEditTime: 2024-03-12 13:54:43
 * @LastEditors: Please set LastEditors
 * @Description: 凭证处理--操作日志
 * @FilePath: \user-intelligent-accounting\src\components\logAlert\index.vue
 -->
<template>
  <en-dialog
    :visible="visible"
    v-if="visible"
    class="log-alert"
    width="900px"
    :modal-append-to-body="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="visible = false"
  >
    <div slot="title" class="dialog-title">
      <div>操作日志</div>
      <div v-if="batch" class="btn-export" @click="handleExport">
        <en-icon
          name="bus-btn5_1"
          size="small"
          style="color: #26c393"
          title="导出"
        ></en-icon>
      </div>
    </div>
    <div class="table-wrap">
      <en-table
        ref="logtable"
        :data="tableData"
        :page-config="{ pageModel, changeMethod: handlePageChanged }"
        height="400px"
        :loading="enLoading"
        @filter-sort-change="handleFilterSortChange"
      >
        <en-table-column type="index" width="60"></en-table-column>
        <en-table-column
          v-for="(itm, idx) in columnData"
          :key="idx"
          :data="itm"
          :width="itm.width"
          :filter-sort-config="itm"
        >
          <template v-slot="{ row }">
            <span v-if="itm.field === 'operator'">
              {{ getOperatorName(row.operator) }}
            </span>
            <span v-else-if="itm.field === 'operDate'">
              {{ row.operDate.slice(0, 16) }}
            </span>
            <span v-else>{{ row[itm.field] }}</span>
          </template>
        </en-table-column>
      </en-table>
    </div>
    <BusinessDownDialog ref="downDialog"></BusinessDownDialog>
  </en-dialog>
</template>

<script>
import { request } from "en-js";
import { intelligentService } from "@/api";
import { base64Encode } from "@/tools/util";

export default {
  name: "LogAlert",
  props: {
    logParam: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    columnData() {
      const arr = [
        {
          field: "operator",
          nameVariable: "operator",
          name: "操作人",
          sortable: true,
          filterable: true,
          type: "001",
          fieldTyp: 1,
          width: 150
        },
        {
          field: "voucherNo",
          nameVariable: "voucherNo",
          name: "操作对象",
          sortable: true,
          filterable: true,
          type: "001",
          fieldTyp: 1
        },
        {
          field: "operatorContents",
          nameVariable: "operatorContents",
          name: "调整结果",
          sortable: true,
          filterable: true,
          type: "001",
          fieldTyp: 1
        },
        {
          field: "operDate",
          nameVariable: "operDate",
          name: "操作时间",
          sortable: true,
          filterable: true,
          type: "007",
          fieldTyp: 7,
          timeFormat: 9,
          width: 150
        }
      ];
      if (!this.batch) {
        arr.splice(1, 1);
      }
      return arr;
    }
  },
  data() {
    return {
      enLoading: false,
      visible: false,
      batch: false,
      tableData: [],
      pageModel: {},
      conditions: {}
    };
  },
  watch: {
    visible(newval) {
      if (newval) {
        this.tableData = [];
        this.resetData();
        this.queryLogList();
      }
    }
  },
  methods: {
    /**
     * @description: 重置查询条件
     */
    resetData() {
      this.pageModel = {
        pageSize: 10,
        pageNo: 1,
        total: "",
        totalPages: ""
      };
      this.conditions = {
        sortFields: "",
        filterFields: ""
      };
    },
    /**
     * @description: 查询列表数据
     * @param {type}
     */
    @request(true, "enLoading")
    async queryLogList() {
      const pa = {
        ...this.logParam,
        ...this.pageModel,
        ...this.conditions
      };
      const rsp = await intelligentService.queryVoucherLog(pa);
      this.pageModel.total = rsp.total;
      this.pageModel.totalPages = rsp.totalPages;
      this.tableData = rsp.records || [];

      this.$nextTick(() => {
        // doLayout
        console.log(this.$refs.logtable);
        // this.$refs.logtable.reloadColumn();
        // this.$refs.logtable.refreshData();
      });
    },
    /**
     * @description: 分页器 事件
     */
    handlePageChanged({ pageNo, pageSize }) {
      this.pageModel.pageNo = pageNo;
      this.pageModel.pageSize = pageSize;
      this.queryLogList();
    },
    /**
     * @description: 获取操作人名称
     */
    getOperatorName(list) {
      const names = [];
      list.forEach((itm) => {
        names.push(itm.name);
      });
      return names.join(",");
    },
    /**
     * @description: 列头筛选排序配置
     */
    getSortConfig(type, field) {
      const config = {
        field,
        filterable: true,
        sortable: true,
        fieldType: type,
        type: `00${type}`
      };
      if (type === 7) {
        config.timeFormat = "9";
      }
      return config;
    },
    /**
     * @description: 列头筛选排序回调
     */
    handleFilterSortChange(param) {
      const { sortData, filterData } = param;
      this.conditions.sortFields = sortData || "";
      this.conditions.filterFields = filterData || "";
      this.queryLogList();
    },
    /**
     * @description: 列头筛选排序回调
     */
    async handleExport() {
      const pa = {
        ...this.logParam,
        ...this.conditions
      };
      pa.sortFields = JSON.stringify(pa.sortFields || {});
      pa.filterFields = JSON.stringify(pa.filterFields || []);
      try {
        // 查询阈值
        const isJump = await intelligentService.queryVoucherInterfaceCount(pa);
        pa.isJump = isJump;
        if (isJump) {
          this.$refs.downDialog.open();
        } else {
          pa.sortFields = base64Encode(pa.sortFields);
          pa.filterFields = base64Encode(pa.filterFields);
          pa.requestType = "stream";
          const res = await intelligentService.exportVoucherInterface(pa);
          const { headers } = res;
          const cd = headers["content-disposition"];
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.download = decodeURIComponent(cd.split("=")[1]);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.log-alert {
  .dialog-title {
    display: flex;
    justify-content: space-between;
    .btn-export {
      margin-right: 30px;
    }
  }
  & /deep/ .el-dialog__header {
    text-align: left;
  }
  & /deep/ .vxe-table--repair {
    height: 0px;
  }
}
</style>
