<!--
 * @Author: pengyu
 * @Date: 2020-07-01 15:42:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-17 14:15:00
 * @Description: 新增凭证-分录表格
-->
<template>
  <en-table
    ref="voucherTable"
    :autoResize="true"
    class="voucher-table"
    :stripe="false"
    :data="itemList"
    :edit-config="{}"
    :cell-class-name="cellClassName"
    :span-method="spanMethod"
    max-height="280"
    @cell-click="handleCellClick"
    show-overflow
  >
    <en-table-column
      title="序号"
      width="60"
      align="center"
      column-key="id"
    >
      <template slot-scope="scope">
        <!-- <span v-if="scope.rowIndex === itemList.length - 2"></span> -->
        <span v-if="scope.rowIndex === itemList.length - 1">合计: {{ getTotal }}</span>
        <span v-else>{{ scope.rowIndex + 1 }}</span>
      </template>
    </en-table-column>
    <en-table-column
      field="remark"
      title="摘要"
    >
      <template v-slot:edit="{row,rowIndex}">
        <!-- 最后一行借方金额合计 -->
        <span v-if="rowIndex === itemList.length - 1">{{ totalD | thousand }}</span>
        <el-popover
          v-else
          placement="bottom-start"
          width="400"
          trigger="click"
          class="zy-alert"
          popper-class="zhaiyao-alert"
          :disabled="actionType===2 || actionType===5"
          :visible-arrow="false"
        >
          <div class="zy-wrap" v-if="row.remarkList">
            <div
              class="node-temps"
              v-for="(itm,idx) in row.remarkList"
              :key="idx"
              :class="row.remark===itm.remark ? 'cur' : ''"
              @click="handleRemarkClick(itm.remark,row)"
            >
              {{itm.remark}}
            </div>
          </div>
          <div slot="reference">
            <el-input
              v-model="row.remark"
              :disabled="Number(actionType)===2"
              placeholder="请输入"
              :class="actionType===5 || voucherType==='001'? 'clr-negative' : ''"
              @focus="getPrevRemark(row,rowIndex)"
              @input="(val)=>getSearchRemark(val,row)"
              @keydown.native="handleZyKeydown($event)"
            ></el-input>
          </div>
        </el-popover>
      </template>
    </en-table-column>
    <en-table-column
      field="subjectId"
      title="科目"
      :show-overflow-tooltip="true"
    >
      <template v-slot:edit="{row,rowIndex}">
        <!-- 最后一行贷方金额合计 -->
        <span v-if="rowIndex===itemList.length-1">{{ totalC | thousand }}</span>
        <div
          v-else-if="actionType===2 || actionType===5"
          :class="actionType===5 || voucherType==='001' ? 'clr-negative' : ''"
        >
          {{`${row.subjectCode} ${row.subjectName}`}}
        </div>
        <en-select
          v-else
          filterable
          v-model="row.subjectId"
          :data="subjectList"
          :props="subjectProps"
          @change="handleSubjectSelect(row)"
          @visible-change="closePopover"
        >
        </en-select>
      </template>
    </en-table-column>
    <en-table-column
      field="fzhs"
      title="辅助核算"
      :show-overflow-tooltip="false"
    >
      <template v-slot:edit="{row, rowIndex}">
        <el-popover
          placement="bottom-start"
          width="360"
          trigger="click"
          class="fzhs-alert"
          :disabled="actionType===2 || actionType===5 || !row.assitAcc"
          @show="popoverFzhsShow(row)"
        >
          <div :id="`fzhs${rowIndex}`" class="fzhs-wrap">
            <div
              class="flex-item"
              v-for="(item,index) in row.fzhsbd"
              :key="index"
            >
              <div class="label">{{item.name}}</div>
              <div class="selection">
                <en-select-data
                  :config="getFzhsConfig(item)"
                  v-model="fzhsForm[item.id]"
                  :modelValue="item.id"
                ></en-select-data>
              </div>
            </div>
          </div>

          <div :style="{'text-align':'right'}">
            <en-button @click="setFzhsCell(row)">
              确认
            </en-button>
          </div>
          <div slot="reference" style="padding:10px;">
            <div
              v-for="(item,index) in row.fzhs"
              :key="index"
              :class="actionType===5 ? 'clr-negative' : ''"
              @contextmenu.prevent
            >
              {{ item.keyName }}：{{ item.valueName || "" }}
            </div>
          </div>
        </el-popover>
      </template>
    </en-table-column>
    <en-table-column
      field="slwb"
      title="数量/外币"
    >
      <template v-slot:edit="{row, rowIndex}">
        <el-popover
          placement="bottom-start"
          width="360"
          trigger="click"
          class="slwb-alert"
          :disabled="actionType===2 || actionType===5 || !row.quantityUnit && !row.forcoinAcc"
          @show="popoverSlwbShow(row, rowIndex)"
        >
          <el-form
            ref="slwbForm"
            :model="countForm"
            label-width="40px"
          >
            <el-form-item
              label="数量"
              prop="quantityAcc"
              v-show="row.quantityUnit"
            >
              <el-input
                v-model="countForm.quantityAcc"
                placeholder="请输入"
                @input="(val)=>{countFormChange(val,'quantityAcc')}"
              ></el-input>
            </el-form-item>
            <div
              class="foro-row"
              v-show="row.quantityUnit"
            >
              <el-form-item
                label="单价"
                prop="price"
              >
                <el-input
                  v-model="countForm.price"
                  placeholder="请输入"
                  @input="(val)=>{countFormChange(val,'price')}"
                ></el-input>
                <span>{{ countForm.foroCurrSys }}</span>
              </el-form-item>
            </div>
            <div class="third-row" v-show="row.forcoinAcc">
              <el-form-item
                label="原币"
                prop="foroCurrSys"
              >
                <en-select
                  v-model="countForm.foroCurrSys"
                  placeholder="请选择"
                  :data="row.foroCurrSysList || []"
                  :props="{value:'value',label:'text'}"
                  @change="getMonthRate"
                >
                </en-select>
              </el-form-item>
              <el-form-item
                label=""
                prop="amount"
              >
                <el-input
                  v-model="countForm.amount"
                  placeholder="金额"
                  @input="(val)=>{countFormChange(val,'amount')}"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item
              label="汇率"
              prop="rate"
            >
              <el-input
                v-model="countForm.rate"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div :style="{'text-align':'right'}">
            <en-button @click="setCountCell(row)">
              确认
            </en-button>
          </div>
          <div
            slot="reference"
            :class="actionType===5 ? 'clr-negative' : ''"
          >
            <div v-show="row && Number(row.quantityAcc || 0)">
              数量：{{ row.quantityAcc }}
            </div>
            <div v-show="row && Number(row.price || 0)">
              单价：{{ row.price }}
            </div>
            <div v-show="row && row.foroCurrSys && (row.amount)">
              {{ row.foroCurrSys }}：{{ row.amount }}
            </div>
            <div v-show="row && Number(row.rate || 0)">
              汇率：{{ row.rate }}
            </div>
          </div>
        </el-popover>
      </template>
    </en-table-column>
    <en-table-column
      field="amountD"
      title="借方金额"
      v-if="showWb"
      :key="Math.random()"
    >
      <en-table-column
        field="foroCurrAmountD"
        title="原币"
      >
        <template v-slot:edit="{row}">
          <div v-if="row.foroCurrAmountD" :class="Number(row.amountD) < 0 ? 'clr-negative': ''">
            {{getAmountShowValue(row.foroCurrAmountD)}}
          </div>
        </template>
      </en-table-column>
      <en-table-column
        field="baseD"
        title="本位币"
      >
        <template v-slot:edit="{row}">
          <div v-if="row.amountD" :class="Number(row.amountD) < 0 ? 'clr-negative': ''">
            {{getAmountShowValue(row.amountD)}}
          </div>
        </template>
      </en-table-column>
    </en-table-column>
    <en-table-column
      field="amountD"
      title="借方金额"
      v-if="!showWb"
      :key="Math.random()"
    >
      <template v-slot:edit="{row, rowIndex}">
        <div
          v-show="row.amountD && !row.isEditamountD"
          class="bmoney"
          :class="Number(row.amountD) < 0 || actionType===5 || voucherType==='001'? 'clr-negative' : ''"
        >
          {{getAmountShowValue(row.amountD)}}
        </div>
        <el-input
          v-show="row.isEditamountD"
          v-model="row.amountD"
          placeholder="请输入"
          :disabled="actionType===2"
          :class="Number(row.amountD) < 0? 'clr-negative' : ''"
          @change="amountChange(row, 'amountD')"
          @blur="row.isEditamountD=null"
          @keydown.native="handleKeyCode($event, row, rowIndex, 'amountD')"
        ></el-input>
      </template>
    </en-table-column>
    <en-table-column
      field="amountC"
      title="贷方金额"
      v-if="showWb"
      :key="Math.random()"
    >
      <en-table-column
        field="foroCurrAmountC"
        title="原币"
      >
        <template v-slot:edit="{row}">
          <div v-if="row.foroCurrAmountC" :class="Number(row.amountC) < 0 ? 'clr-negative': ''">
            {{getAmountShowValue(row.foroCurrAmountC)}}
          </div>
        </template>
      </en-table-column>
      <en-table-column
        field="baseC"
        title="本位币"
      >
        <template v-slot:edit="{row}">
          <div v-if="row.amountC" :class="Number(row.amountC) < 0 ? 'clr-negative': ''">
            {{getAmountShowValue(row.amountC)}}
          </div>
        </template>
      </en-table-column>
    </en-table-column>
    <en-table-column
      field="amountC"
      title="贷方金额"
      v-if="!showWb"
      :key="Math.random()"
    >
      <template v-slot:edit="{row, rowIndex}">
        <div
          v-show="row.amountC && !row.isEditamountC"
          class="dmoney"
          :class="Number(row.amountC) < 0  || actionType===5 || voucherType==='001'? 'clr-negative' : ''"
        >
          {{ getAmountShowValue(row.amountC)}}
        </div>
        <el-input
          v-show="row.isEditamountC && actionType!==2"
          v-model="row.amountC"
          placeholder="请输入"
          :disabled="actionType===2"
          :class="Number(row.amountC) < 0 ? 'clr-negative' : ''"
          @change="amountChange(row, 'amountC')"
          @blur="row.isEditamountC=null"
          @keydown.native="handleKeyCode($event, row, rowIndex, 'amountC')"
        ></el-input>
      </template>
    </en-table-column>
    <template #hoverRow="{row, rowIndex}" >
      <en-expand-buttons
        v-if="rowIndex!==itemList.length-1"
        :data="btnList"
        :min-number="1"
        @button-click="handleRowBtnClick(row, rowIndex, arguments[1], arguments[0])"
      />
    </template>
  </en-table>
</template>

<script>
import { cloneDeep } from "lodash";
import { thousand, currency as convertCurrency, executeNum } from "en-js";
import { Message } from "element-ui";
import { voucherDetail } from "@/api";
import enSelectData from "@/components/en-select-data";

export default {
  name: "VoucherTable",
  props: {
    accountId: {
      type: String,
      default: ""
    },
    subjectList: { // 科目数据
      type: Array,
      default: () => []
    },
    actionType: { // 1新增2查看3编辑4复制5冲销
      type: Number,
      default: 0
    },
    isFor: { // 是否有外币
      type: Number,
      default: 1
    },
    voucherType: { // 凭证类型 000非冲销凭证 001冲销凭证
      type: String,
      default: ""
    }
  },
  components: { enSelectData },
  inject: ["getItemList", "getVcHeader"],
  watch: {
    isFor() { // 0-有外币  1-无外币
      this.$forceUpdate();
      this.$refs.voucherTable.reLayout();
      this.$refs.voucherTable.refreshColumn();
    }
  },
  data() {
    return {
      btnData: [
        {
          id: "add", name: "添加", icon: "tianjia-liebiao", iconColor: "#4693E1"
        }, {
          id: "delete", name: "删除", icon: "shanchu-liebiao"
        }
      ], // 分录行操作按钮
      countForm: {
        amount: "",
        foroCurrSys: "",
        foroCurrSysName: "",
        price: "",
        quantityAcc: "",
        rate: ""
      }, // 数量外币对象
      fzhsForm: {}, // 单条辅助核算对象
      subjectMap: {}, // 科目map
      subjectProps: { value: "id", label: "formatText" },
      fzhsBaseData: {}, // 辅助核算对应的基础数据
      commonData: {
        remark: "", // 摘要
        subjectId: "", // 科目id
        amountD: "", // 借方本位币
        amountC: "", // 贷方本位币
        foroCurrAmountD: "", // 借方原币金额
        foroCurrAmountC: "", // 贷方原币金额
        amount: "", // 原币金额
        quantityAcc: "", // 数量
        foroCurrSys: "", // 币种
        foroCurrSysName: "", // 币种name
        price: "", // 单价
        rate: "", // 汇率
        fzhs: [] // 辅助核算
      }, // 单条分录
      operateIndex: 0
    };
  },
  computed: {
    itemList() {
      return this.getItemList();
    },
    vcHeader() {
      return this.getVcHeader();
    },
    totalD() { // 借方合计
      let num = 0;
      const _this = this;
      this.itemList.forEach((item) => {
        if (_this.actionType === 5) {
          num += 0 - (Number(isNaN(item.amountD) ? 0 : item.amountD));
        } else {
          num += Number(isNaN(item.amountD) ? 0 : item.amountD);
        }
      });

      return num;
    },
    totalC() { // 贷方合计
      let num = 0;
      const _this = this;
      this.itemList.forEach((item) => {
        if (_this.actionType === 5) {
          num += 0 - (Number(isNaN(item.amountC) ? 0 : item.amountC));
        } else {
          num += Number(isNaN(item.amountC) ? 0 : item.amountC);
        }
      });

      return num;
    },
    getTotal() { // 合计
      let c = 0; let d = 0;
      this.itemList.forEach((item) => {
        if (item.amountC) {
          c += Number(item.amountC);
        } else if (item.amountD) {
          d += Number(item.amountD);
        }
      });
      if (this.actionType === 5) {
        c = 0 - c;
        d = 0 - d;
      }
      if (c && c === d) { // 借贷相等
        return convertCurrency(c);
      }
      return "";
    },
    btnList() {
      const at = this.actionType;
      switch (at) {
        case 1:
        case 3:
        case 4:
          return this.btnData;
        case 2:
          return [];
        case 5:
          return [this.btnData[1]];
        default:
          return [];
      }
    },
    showWb() { // 展示数量外币
      return this.actionType === 2 && this.isFor === 0;
    }
  },
  filters: {
    thousand(value, decimal = 2) {
      return thousand(Number(value), decimal);
    }
  },
  methods: {
    /**
     * @description: 设置部分表格样式
     * @param column
     */
    cellClassName({ column }) {
      const { field } = column;
      const { actionType } = this;
      const cla = [];
      // if (![1, 2, 3, 5].includes(actionType)) {
      //   return "";
      // }
      cla.push(`${field}-td`);

      if (field === "subjectId" && [1, 3].includes(actionType)) {
        cla.push("input-no-padding");
      }

      if ((field === "slwb" || field === "fzhs") && actionType === 2) {
        cla.push("is-disabled");
      }

      return cla.join(" ");
    },
    /**
     * @description: 合并行操作
     * @param rowIndex
     * @param columnIndex
     */
    /* eslint-disable */
    spanMethod({ rowIndex, columnIndex }) {
      const len = this.itemList.length; 
      if (rowIndex === len - 1) { // 最后一行合并5列
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 5
          };
        } else if (columnIndex > 0 && columnIndex < 3) {
          return {
            rowspan: 1,
            colspan: this.showWb ? 2: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    /**
     * @description: 表格td点击事件
     * @param {type}
     */
    handleCellClick({ row, column, cell }) {
      const { field } = column;
      if(this.actionType===2){
        return;
      }
      this.$set(row, `isEdit${field}`, true);
      if (field === "amountD") {
        this.$set(row, "isEditamountC", null);
      }else if(field === "amountC") {
         this.$set(row, "isEditamountD", null);
      }
      const ipt = this.getChildNode(cell, "el-input__inner");
      setTimeout(function(){
        if(ipt){ipt.focus();}}, 1);
    },
    /**
     * @description: 摘要列表点击
     * @param val 值
     * @param row 行数据
     */
    handleRemarkClick(val,row){
      this.$set(row, "remark", val);
      document.body.click();
    },
    /**
     * @description: 表格行按钮
     * @param row 行数据
     * @param rowIndex 行数据索引
     * @param btn 按钮
     */
    handleRowBtnClick(row, rowIndex, btn) {
      if(rowIndex === -1){
        rowIndex = this.operateIndex;
      }else{
        this.operateIndex = rowIndex;
      }

      const { id } = btn;
      if (id === "add") {
        this.itemList.splice(rowIndex, 0 , cloneDeep(this.commonData));
      } else if (id === "delete") {
        if (this.itemList.length === 2) {
          return;
        }
        this.itemList.splice(rowIndex, 1);
      }
      this.$forceUpdate();
    },
    /**
     * @description: 切换科目，重置数据
     * @param row 行数据
     */
    resetRowData(row) {
      row.amountC = "";
      row.amountD = "";
      row.foroCurrAmountD = "";
      row.foroCurrAmountC = "";
      row.amount = "";
      row.fzhs = [];
      row.fzhsbd = [];
      Object.keys(this.countForm).forEach((key) => {
        row[key] = "";
      });
    },
    /**
     * @description: 查询摘要信息
     * @param val
     * @param row 行数据
     */
    async getSearchRemark(val, row) {
        const pa = { accountId: this.accountId, searchValue: val };
        const rsp = await voucherDetail.queryMark(pa);
        this.$set(row, "remarkList", rsp);
    },
    /**
     * @description: 获取上一行摘要
     * @param row
     * @param rowIndex
     */
    getPrevRemark(row, rowIndex) {
      if (rowIndex > 0 && !row.remark) {
        row.remark = this.itemList[rowIndex - 1].remark;
      }else if(row.remark){
        this.getSearchRemark(row.remark, row);
      }
    },
    /**
     * @description: 选择科目
     * @param row 行数据
     */
    async handleSubjectSelect(row) {
      const _this = this;
      const { subjectId } = row;
      const subItem = cloneDeep(this.subjectList.filter((itm) => itm.id === subjectId));
      //不覆盖id
      delete subItem[0].id;
      subItem[0].subjectCode = subItem[0].code;
      subItem[0].subjectName = subItem[0].name;
      Object.assign(row, subItem[0]);
      _this.resetRowData(row);
      if (row.assitAcc) { // 有辅助核算 先查询辅助核算对应的基础数据
        const assitAccAry = row.assitAcc.split(",");
        const fzhsbd = [];
        row.fzhs = [];
        for (let i = 0; i < assitAccAry.length; i++) {
          let dd;
          if (_this.fzhsBaseData[i]) {
            dd = _this.fzhsBaseData[i];
          } else {
            // eslint-disable-next-line no-await-in-loop
            const re = await voucherDetail.queryObjectTypeByIds({ ids: assitAccAry[i] });
            re[0].value = "";
            dd = re[0];
            _this.fzhsBaseData[i] = dd;
          }
          fzhsbd.push(dd);
        }
        row.fzhsbd = fzhsbd;
      }

      // 刷新数据
      this.itemList.splice(0, 0);
      this.triggerNext(row , "subjectId");
    },
    /**
     * @description: 辅助核算设置
     * @param rowData
     */
    async popoverFzhsShow(rowData) {
      // 给辅助核算查询下拉项
      const _this = this;
      const { fzhsbd, fzhs } = rowData;
      this.fzhsForm = {};
      for (let i = 0; i < fzhsbd.length; i++) {
        const fs = fzhs.filter(itm => itm.key === fzhsbd[i].id)[0];
        if (fs) {
          _this.$set(_this.fzhsForm, fzhsbd[i].id, {id: fs.value || fs.id, name: fs.valueName || fs.name});
        } else {
          _this.$set(_this.fzhsForm, fzhsbd[i].id, "");
        }
      }

      this.itemList.splice(0, 0);
    },
    /**
     * @description: 设置辅助核算
     * @param rowData 行数据
     */
    setFzhsCell(rowData) {
      const valueArr = Object.values(this.fzhsForm);
      const kvArr = Object.entries(this.fzhsForm);
      if (valueArr.includes("")) {
        Message("请选择辅助核算项");
        return;
      }
      const { fzhsbd } = rowData;
      const re = [];
      kvArr.forEach((itm) => {
        const fs = fzhsbd.findIndex((a) => a.id === itm[0]);
        re.push({
          key: itm[0],
          keyName: fzhsbd[fs]?.name || "",
          value: itm[1].id,
          valueName: itm[1].name
        });
      });
      rowData.fzhs = re;
      this.itemList.splice(0, 0);
      document.body.click();// 触发隐藏popover
      this.fzhsForm = {};
      //设置完后，触发设置数量外币
      this.triggerNext(rowData, "fzhs");
    },
    /**
     * @description: 数量/外币设置
     * @param row
     * @param rowIndex
     */
    popoverSlwbShow(row, rowIndex) {
      // 设置币种下拉
      const { forcoinAcc, forcoinAccName } = row;
      const fac = forcoinAcc.split(",");
      const facn = forcoinAccName.split(",");
      const ary = [];
      fac.forEach((itm, idx) => {
        facn.forEach((citm, cidx) => {
          if (idx === cidx) {
            ary.push({
              value: itm,
              text: citm || itm
            });
          }
        });
      });
      row.foroCurrSysList = ary;

      Object.keys(this.countForm).forEach((key) => {
        this.countForm[key] = cloneDeep(row[key]);
      });
      // this.$set(this.itemList, rowIndex, row);
    },
    /**
     * @description: 设置数量外币 显示
     * @param rowData 分录行数据
     */
    setCountCell(rowData) {
      rowData = Object.assign(rowData, this.countForm);
      if (rowData.amountC) {
        rowData.amountC = executeNum(Number(rowData.amount), Number(rowData.rate), 2, 2);
        rowData.amountD = "";
      } else {
        rowData.amountD = executeNum(Number(rowData.amount), Number(rowData.rate), 2, 2);
        rowData.amountC = "";
      }

      Object.keys(this.countForm).forEach((key) => {
        this.countForm[key] = "";
      });
      document.body.click();// 触发隐藏popover
      this.triggerNext(rowData, "slwb");
    },
    /**
     * @description: 获取基础数据下拉项 （辅助核算）
     * @param data
     * @return:
     */
    async getFzhsOption(id) {
      const pa = { objectTypeId: id, pageSize: 20 };
      const rsp = await voucherDetail.queryObjectTreeExt(pa);
      // 树形结构需要特殊处理
      return rsp;
    },
    /**
    * @description: 借方 贷方金额 输入框事件
    * @param rowData 行数据
    * @param key 借 or 贷
    */
    amountChange(rowData, key) {
      //借贷取一个 清空另一个
      if(key === "amountC"){
        rowData.amountD = ""
        this.$set(rowData, "isEditamountC", null);
      }else if(key === "amountD"){
        rowData.amountC = ""
        this.$set(rowData, "isEditamountD", null);
      }
      if (rowData[key] && isNaN(rowData[key])) {
        rowData[key] = "";
      }
      if (rowData[key] && (rowData[key].toString()).includes(".")){
        const index = rowData[key].indexOf(".");
        //取两位小数
        if(rowData[key].length - index > 3){
          rowData[key] = rowData[key].substring(0, index+3);
        }
      }
      let { rate, price, quantityAcc, amount } = rowData;
      // 改变借贷方金额，重置汇率
      if(!!amount){ //原币值
        rate = Math.abs(executeNum(rowData[key], amount, 3, 6));
        amount = executeNum(rowData[key] > 0 ? 1 : -1, Math.abs(amount), 2, 2);
        quantityAcc = amount>=0 ? Math.abs(quantityAcc) : -Math.abs(quantityAcc);
        this.$set(rowData, "rate", rate);
        this.$set(rowData, "amount", amount);
        this.$set(rowData, "quantityAcc", quantityAcc);
      }else if(!!quantityAcc){ //有数量值
        price = Math.abs(executeNum(rowData[key],quantityAcc,3,4));
        quantityAcc = executeNum(rowData[key]>0?1:-1,Math.abs(quantityAcc),2,2);
        this.$set(rowData, "price", price);
        this.$set(rowData, "quantityAcc", quantityAcc);
      }

      this.timer = null;
    },
    /**
    * @description: 借方 贷方金额 输入框keyup事件
    * @param evt event对象
    * @param row 行数据
    * @param rowIndex 行索引
    * @param key
    */
    handleKeyCode(evt, row, rowIndex, key){
      const {keyCode, target} = evt;
      if(keyCode === 9 || keyCode === 13){ //tab enter
        if(key === "amountD"){ //借方
          const amdtd = this.getParentNode(target, "amountD-td");
          const amctd = amdtd.nextSibling;
          const amcipt = this.getChildNode(amctd, "el-input__inner");
          row.isEditamountD = null;
          row.isEditamountC = true;
          setTimeout(function(){amcipt.focus();}, 100);
        } else { //贷方
          //摘要focus
          if(rowIndex + 1 < this.itemList.length){
            const {id} = row;
            const tr = document.querySelector(`tr[data-rowid=${id}]`);
            const nextTr = tr.nextSibling;
            const zytd = this.getChildNode(nextTr, "remark-td");
            const zyipt = this.getChildNode(zytd, "el-input__inner");
            row.isEditamountD = null;
            row.isEditamountC = null;
            setTimeout(function(){zyipt.focus();}, 100);
          }
        }
      }else if(keyCode === 32){ //spacebar
        row.isEditamountD = null;
        row.isEditamountC = null;
        if(key==="amountD"){ //借
          const amdtd = this.getParentNode(target, "amountD-td");
          const amctd = amdtd.nextSibling;
          const amcipt = this.getChildNode(amctd, "el-input__inner");
          setTimeout(function(){amcipt.focus();}, 100);
          row.isEditamountC = true;
          row.isEditamountD = null;
          row.amountC = row.amountD;
          row.amountD = "";
        }else{
          const amctd = this.getParentNode(target, "amountC-td");
          const amdtd = amctd.previousSibling;
          const amdipt = this.getChildNode(amdtd, "el-input__inner");
          setTimeout(function(){amdipt.focus();}, 100);
          row.isEditamountD = true;
          row.isEditamountC = null;
          row.amountD = row.amountC;
          row.amountC = "";
        }
      }else if(keyCode === 187){ // +=号
        setTimeout(()=>{
          let sumD = 0; //合计借
          let sumC = 0; //合计贷
          this.itemList.forEach((itm,idx)=>{
            if(idx !== rowIndex){
              sumD += Number(itm.amountD || 0);
              sumC += Number(itm.amountC || 0);
            }
          });
          if(key === "amountD"){
            const value = executeNum(sumC, sumD, 1, 2);
            row.amountC = "";
            row[key] = value;
          }else{
            const value = executeNum(sumD, sumC, 1, 2);
            row.amountD = "";
            row[key] = value;
          }
        },1)
      }
    },
    /**
     * @description: 数量|外币设置 计算
     * @param val
     * @param key
     */
    countFormChange(val, key) {
      if (isNaN(val) || val === "") {
        this.countForm[key] = "";
      } else {
        this.countForm[key] = Number(val);
        if (key === "quantityAcc") { // 数量
          this.countForm.amount = this.countForm.price === "" ? "" : Number(this.countForm.price) * Number(val);
        } else if (key === "price") { // 单价
          this.countForm.amount = this.countForm.quantityAcc === "" ? "" : Number(this.countForm.quantityAcc) * Number(val);
        } else if (key === "amount") { // 金额
          this.countForm.price = this.countForm.quantityAcc === "" ? "" : Number(val) / Number(this.countForm.quantityAcc);
        }
      }
    },
    /**
     * @description: 科目下拉框显示，该方法是为了触发popover的隐藏
     */
    closePopover(value){
      value && document.body.click();
    },
    /**
     * @description: 触发下一步操作 弹窗或focus
     * @param rowData 行数据
     * @param curStep 当前操作项
     */
    triggerNext(rowData, curStep){
      //此处为dom操作
      //交互：选中科目含辅助核算，则自动弹出辅助核算设置框，没有则借方金额focus
      //设置完辅助核算确定，弹出数量外币设置
      //设置完数量外币设置则focus金额
      const { id } = rowData;
      // const tr = document.querySelector("tr[data-rowid='"+id+"']");
      const tr = this.$refs.voucherTable.getTrElemenet(id);
      const index = this.itemList.findIndex(itm=>itm.id===id);
      if(Object.keys(this.itemList[index+1]).length===1){
        setTimeout(()=>{
          this.itemList.splice(index+1,0,JSON.parse(JSON.stringify(this.commonData)));
        }, 100);
      }

      if(curStep=== "subjectId"){
        if(rowData.assitAcc){
          tr.querySelector('.fzhs-td .el-popover__reference').click();
        }else if(rowData.quantityUnit || rowData.forcoinAcc){
          tr.querySelector(".slwb-td .el-popover__reference").click();
        }else{
          tr.querySelector('.amountD-td').click();
        }
      }else if(curStep === "fzhs"){
        tr.querySelector(".slwb-td .el-popover__reference").click();
      }else if(curStep === "slwb"){
        if(rowData.amountD){
          tr.querySelector('.amountC-td').click();
        }else{
          tr.querySelector('.amountD-td').click();
        }
      }
    },
    /**
     * @description: 获取辅助核算下拉配置
     * @param item   
     */
    getFzhsConfig(item){
      const data = cloneDeep(item);
      return {
        lazy: true,
        page: true,
        defaultProps: {value:'id',label:'name', isLeaf: "leaf", children: "children"},
        placeholder: "请选择",
        selectMode: "normal",
        dependData: {
          requestService: voucherDetail,
          requestFn: "bdQueryObjectTree",
          requestParams: { objectTypeId: data.id, pageSize: 20, pageNo: 1 }
        }
      }
    },
    /**
     * @description: 根据远比查询汇率
     * @param value
     */
    async getMonthRate(value){
      const objectId = this.vcHeader.accountingPeriodMainId;
      const name = this.vcHeader.accountingPeriodName;
      if(!objectId){
        Message("请选择正确的记账日期");
        return;
      }
      const pa = {
        timeId: objectId,
        timeDetailName: name,
        forCoin: value
      };
      const rsp = await voucherDetail.queryMonthRate(pa);
      this.$set(this.countForm, "rate", rsp.item5 || "");
    },
    /**
     * @description: 
     * @param value
     */
    handleZyKeydown(e) {
      const {keyCode, target} = e;
      const zytd = this.getParentNode(target, "remark-td");
      if(zytd){
        const kmtd = zytd.nextSibling;
        const kmipt = this.getChildNode(kmtd, "el-input__inner");
        if(keyCode === 13){ //enter
          target.blur();
          kmipt.focus();
        }
      }
    },
    /**
     * @description: 金额展示
     * @param value  金额
     */
    getAmountShowValue(value){
      if(Number(value) === 0){
        return ""
      }
      return thousand(Math.abs(value));
    },
    getParentNode(node, claName){
      if(!node) return null;
      const {parentNode, className} = node;
      const classArr = className.split(" ");
      if(classArr.includes(claName)){
        return node;
      }else{
        return this.getParentNode(parentNode, claName);
      }
    },
    getChildNode(node, claName){
      const _this = this;
      if(!node) return null;
      const {childNodes, className} = node;
      const classArr = (className||"").split(" ");
      if(classArr.includes(claName)){
        return node;
      }else{
        for(let i=0; i<childNodes.length; i++){
          const res = _this.getChildNode(childNodes[i], claName);
          if(res){
            return res;
          }
          continue;
        }
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.voucher-table{
    height: 100%;
    & /deep/ .vxe-table--repair{//去除表格粗线
      height: 0;
    }
    & /deep/ .vxe-table:after{
      height: 0;
    }
    & /deep/ .vxe-header--row {
      .vxe-cell {
        font-size: 14px;
        padding: 0 14px;
        line-height: 50px;
      }
    }
    & /deep/ .vxe-table--body-wrapper{
      overflow-x: hidden;
    }
    & /deep/ .vxe-cell {
      width: 100%;
      height: 45px;
    }
    & /deep/ .vxe-body--row {
      td:first-child {
        line-height: 45px;
      }
      td:nth-last-child(2),
      td:last-child {
        .el-input__inner {
          text-align: right;
        }
      }
      &:last-child td:last-child{
        border-right: 0;
      }
    }
    .fzhs-alert{
      display: inline-block;
      width: 100%;
      height: 100%;
    }
    & /deep/ .slwb-td,
    & /deep/ .fzhs-td {
      .el-popover__reference {
        padding: 4px 12px;
        overflow-y: auto ;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        >div{
          line-height: 18px;
        }
      }
    }
    & /deep/ .subjectId-td,
    & /deep/ .foroCurrAmountD-td,
    & /deep/ .foroCurrAmountC-td,
    & /deep/ .amountD-td,
    & /deep/ .amountC-td,
    & /deep/ .baseD-td,
    & /deep/ .baseC-td{
      .vxe-cell{
        font-size: 14px;
        line-height: 45px;
        padding: 0 14px;
      }
    }
    & /deep/ .input-no-padding .el-input__inner{
      padding: 0;
    }
    & /deep/ .clr-negative {
      color: #f35d5e;
      > div {
        color: #f35d5e;
      }
      .el-input__inner {
        color: #f35d5e !important;
      }
    }
    & /deep/ .el-popover__reference {
      height: 100%;
    }
    & /deep/ .vxe-body--column {
      overflow: hidden;
    }
    & /deep/ .el-input__inner {
      border: none;
    }
    & /deep/ tr:last-child {
      font-weight: 600;
      .vxe-cell {
        width: inherit !important;
      }
      td {
        line-height: 45px;
        text-align: right;
        .vxe-cell {
          font-size: 14px;
          padding: 0 14px;
        }
      }
      td:first-child {
        width: 100% !important;
        text-align: left;
      }
    }
    & /deep/ .is-disabled {
      .el-select__caret {
        display: none;
      }
      .el-input__inner{
        background: #ffffff;
        color: #636C78;
        cursor: default;
      }
    }
    .zy-alert{
      .el-input{
        height:45px;
        width: 100%;
        /deep/.el-input__inner{
          height:45px;
          background: inherit;
        }
      }
    }
    & /deep/ .row--hover{
      .el-input__inner{
        background: #f5f8fc;
      }
    }
    .bmoney,.dmoney{
      height: 100%;
      text-align: right;
      line-height: 45px;
    }
}
</style>
<style lang="scss">
  .zhaiyao-alert{
    border: none;
    padding: 0;
  }
  .zy-wrap{
    height: 230px;
    overflow-y: auto;
    font-size: 12px;
    .node-temps{
      padding: 10px;
      &:hover{
        background: #f5f8fc;
      }
      &.cur{
        color: #3e90fe;
      }
    }
  }
  .fzhs-wrap {
    .flex-item{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .label{
        font-size: 12px;
        width: 60px;
        text-align: center;
      }
      .selection{
        flex: 1;
      }
    }
    .en-select {
      width: 100%;
    }
  }
  .third-row {
    display: flex;
    & /deep/ .el-form-item:last-child {
      .el-form-item__content {
        margin-left: 10px !important;
      }
    }
  }
  .foro-row {
    .el-form-item__content {
      display: flex;
      .el-input {
        width: 90%;
      }
      span {
        flex: 1;
        text-align: center;
      }
    }
  }
</style>
