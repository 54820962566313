<!--
 * @Author: pengyu
 * @Date: 2020-03-20 17:41:10
 * @LastEditTime: 2020-11-27 11:53:35
 * @LastEditors: pengyu
 * @Description: 新增凭证--创建模板
 * @FilePath: \user-intelligent-accounting\src\components\createModel\index.vue
 -->
<template>
  <en-dialog
    :visible="visible"
    title="创建模板"
    class="create-alert"
    width="700px"
    height="400px"
    :modal-append-to-body="false"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="visible=false"
  >
    <div slot="footer">
      <en-button @click="submit">
        确定
      </en-button>
    </div>
    <el-form ref="form" label-width="80px">
      <el-form-item label="模板名称" prop="templateName">
        <el-input v-model="templateName" placeholder="请输入" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="保存金额" prop="amoutFlag" align="left">
        <el-switch
          v-model="amoutFlag"
          :active-value="0"
          :inactive-value="1"
        >
        </el-switch>
      </el-form-item>
    </el-form>
  </en-dialog>
</template>

<script>
import { Message } from "element-ui";
import { voucherDetail } from "@/api";

export default {
  name: "CreateModel",
  props: {
    itemList: { // 分录数据
      type: Array,
      default: () => []
    },
    yearNum: {
      type: String,
      default: ""
    },
    accountId: {
      type: String,
      default: ""
    }
  },
  watch: {
    visiable() {
      this.$nextTick(() => {
        this.templateName = "";
        this.amoutFlag = 1;
      });
    },
    itemList(nVal) {
      this.list = JSON.parse(JSON.stringify(nVal));
    }
  },
  data() {
    return {
      visible: false,
      list: [],
      templateName: "",
      amoutFlag: 1
    };
  },
  methods: {
    async submit() {
      const {
        templateName, amoutFlag, list, accountId, yearNum
      } = this;
      if (!templateName) {
        Message("请输入模板名称");
        return;
      }
      if (!list.length) {
        Message("没有分录行信息");
        return;
      }
      if (amoutFlag === 1) {
        this.list.forEach((itm) => {
          itm.amountD = 0;
          itm.amountC = 0;
        });
      }

      const pa = {
        accountId,
        templateName,
        amoutFlag,
        yearNum,
        itemList: JSON.stringify(list)
      };
      await voucherDetail.saveCollectVoucher(pa);
      Message("收藏模板成功");
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.create-alert{
  & /deep/ .el-dialog__header{
    text-align: left;
  }
  & /deep/ .el-form{
    height: 200px;
  }
}
</style>
