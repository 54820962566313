<!--
 * @Author: pengyu
 * @Date: 2020-12-07 14:41:28
 * @LastEditors: pengyu
 * @LastEditTime: 2021-06-29 11:26:01
 * @Description: 凭证-业务单据列表
-->
<template>
  <en-dialog
    :visible="visible"
    title="业务单据"
    class="business-grid"
    width="1100"
    append-to-body
    @close="visible = false"
  >
    <en-table
      class="table"
      :loading="loading"
      :data="tableData"
      :page-config="{ pageModel: pageModel, changeMethod: handlePageChanged }"
      @row-click="handleRowClick"
      column-min-width="120"
      @filter-sort-change="handleFilterSortChange"
      height="400px"
    >
      <table-column
        v-for="(col, index) in columnData"
        :key="col.id || index"
        :data="col"
        :isHomePage="true"
      >
      </table-column>
    </en-table>
  </en-dialog>
</template>

<script>
import { voucherDetail } from "@/api";
import tableColumn from "@/components/en-table-extra/tableColumn";
import { BusinessDataOpenType } from "@/components/businessDetail/data/BusinessCommonHeader";
// import { Message } from "element-ui";

export default {
  name: "BusinessGrid",
  components: { tableColumn },
  props: {
    busTemplateId: {
      type: String
    },
    sourceId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      pageModel: {
        pageSize: 10,
        pageNo: 1,
        total: 0
      },
      columnData: [],
      tableData: []
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.init();
      }
    }
  },
  methods: {
    async init() {
      await this.queryReportTotalList();
      await this.queryReportTotalDataPage();
    },
    /**
     * @description 查询列头
     * */
    async queryReportTotalList() {
      const pa = { dataType: 3, sourceId: this.busTemplateId };
      const rsp = await voucherDetail.queryReportTotalList(pa);
      const cols = [];
      rsp.fields?.forEach((itm) => {
        if (itm.nameVariable === "BILLSTATUS") {
          itm.nameVariable = "BILLSTATUSNAME";
          itm.type = 1;
        }
        cols.push({
          field: itm.nameVariable,
          name: itm.name,
          fieldType: itm.type,
          sort: true,
          filter: true,
          areaObjTypeId: itm.baseDataType,
          areaObjType: itm.baseDataTableType,
          timeFormat: itm.timeFormat || 8
        });
      });
      this.columnData = [...cols];
    },
    /**
     * @description 查询数据
     * */
    async queryReportTotalDataPage() {
      this.loading = true;
      try {
        const con = {
          nameVariable: "id", type: 1, value: this.sourceId, isTextIn: 0
        };
        const pa = {
          dataType: 3, sourceId: this.busTemplateId, conditions: JSON.stringify([con]), ...this.pageModel
        };
        const rsp = await voucherDetail.queryReportTotalDataPage(pa);
        this.loading = false;
        this.tableData = rsp.records;
        this.pageModel.total = rsp.total;
      } catch (error) {
        // Message(error);
        this.loading = false;
      }
    },
    /**
     * @description 分页事件
     * */
    handlePageChanged({ pageSize, pageNo }) {
      this.pageModel.pageSize = pageSize;
      this.pageModel.pageNo = pageNo;
      this.queryReportTotalDataPage();
    },
    /**
     * @description 行点击事件  跳转业务建模详情
     * */
    handleRowClick({ row }) {
      const routeData = this.$router.resolve({
        path: "/businessModel/detail",
        query: {
          id: row.ID,
          tempalteId: row.TEMPLATEID,
          businessType: 2,
          openType: BusinessDataOpenType.view,
          viewType: this.$route.query.viewType
        }
      });
      window.open(routeData.href, "_blank");
    },
    /**
     * @description 列头筛选排序
     * */
    handleFilterSortChange(params) {
      const { type } = params;
      if (type === "sort") { // 排序
        this.pageModel.sortFields = params.sortData;
      } else if (type === "filter") {
        this.pageModel.filterFields = params.filterData;
      } else {
        this.pageModel.sortFields = "{}";
        this.pageModel.filterFields = "[]";
      }
      this.queryReportTotalDataPage();
    }
  }
};
</script>

<style lang='scss' scoped>
.business-grid{
  & /deep/ .vxe-table--repair{
    height:0;
  }
}
</style>
