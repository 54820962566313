/*
 * @Author: pengyu
 * @LastEditTime: 2023-10-24 17:51:32
 * @LastEditors: linshanfeng
 * @Description: 凭证处理的 一些事件  分录列表公用
 * @FilePath: \user-intelligent-accounting\src\mixins\voucherButtonEvent.js
 */
import { Message, MessageBox } from "element-ui";
import { mapMutations } from "vuex";
import _ from "lodash";
import { intelligentService, voucherDetail } from "@/api";
import { base64Encode } from "@/tools/util";

export default {
  methods: {
    /**
     * @description: 顶部按钮事件
     * @param id     操作类型
     */
    topBtnClick(config) {
      const { btnKey } = config;
      if (btnKey === "screen") { // 筛选
        this.$refs.popScreen.visible = true;
      } else if (btnKey === "break") { // 断号
        this.brokenConfirm();
      } else if (btnKey === "submit") { // 提交
        // 只能提交  状态为  未提交  的 数据
        const dd = this.getCheckedData();
        this.submitVoucher(dd);
      } else if (btnKey === "print") { // 打印
        this.popPrint();
      } else if (btnKey === "delete") { // 删除
        this.deleteConfirm(null);
      } else if (btnKey === "add") { // 添加
        this.setProps();
        this.voucherId = "";
        this.actionType = 1;
        this.$refs.VDA.visible = true;
      } else if (btnKey === "check") { // 审核
        this.handleVouOperate(null, 1);
      } else if (btnKey === "cancelCheck") { // 取消审核
        this.handleVouOperate(null, 2);
      } else if (btnKey.includes("custom")) { // 自定义按钮
        const dd = this.getCheckedData();
        this.emitCustomBtn(config, dd.join(","));
      } else if (btnKey === "export") { // 导出
        this.exportVoucher();
      } else if (btnKey === "voucherLog") { // 凭证日志
        this.logParam = {
          accountId: this.params.accountId,
          accountName: (() => {
            let n = "";
            this.accList.forEach((itm) => {
              if (itm.id === this.params.accountId) {
                n = itm.name;
              }
            });
            return n;
          })(),
          accountingEntity: this.params.accountingEntity,
          accountingEntityName: (() => {
            let n = "";
            this.entityList.forEach((itm) => {
              if (itm.id === this.params.accountingEntity) {
                n = `${itm.code} ${itm.name}`;
              }
              (itm.nodes || []).forEach((citm) => {
                if (citm.id === this.params.accountingEntity) {
                  n = `${citm.code} ${citm.name}`;
                }
              });
            });
            return n;
          })(),
          accountingPeriod: this.params.accountingPeriod,
          accountingPeriodName: this.dateLineParam.accountingPeriodName
        };
        this.$refs.popLog.batch = true;
        this.$refs.popLog.visible = true;
      }
    },
    /**
     * @description: 查看选中数据
     */
    getCheckedData() {
      const cd = [];
      this.voucherData.forEach((item) => {
        if (item.checked) {
          cd.push(item);
        }
      });
      return cd;
    },
    ...mapMutations("intelligentAccounting", [
      "setCacheVoucherData"
    ]),
    /**
     * @description: 单条数据按钮点击
     * @param obj
     */
    async singleClick(obj) {
      const {
        id, data, callback
      } = obj;
      if (id === "log") {
        this.logParam = {
          voucherId: data.id,
          accountId: this.params.accountId,
          accountName: (() => {
            let n = "";
            this.accList.forEach((itm) => {
              if (itm.id === this.params.accountId) {
                n = itm.name;
              }
            });
            return n;
          })(),
          accountingEntity: this.params.accountingEntity,
          accountingEntityName: (() => {
            let n = "";
            this.entityList.forEach((itm) => {
              if (itm.id === this.params.accountingEntity) {
                n = `${itm.code} ${itm.name}`;
              }
              (itm.nodes || []).forEach((citm) => {
                if (citm.id === this.params.accountingEntity) {
                  n = `${citm.code} ${citm.name}`;
                }
              });
            });
            return n;
          })(),
          accountingPeriod: this.params.accountingPeriod,
          accountingPeriodName: this.dateLineParam.accountingPeriodName
        };
        this.$refs.popLog.batch = false;
        this.$refs.popLog.visible = true;
      } else if (id === "edit") {
        this.setProps();
        this.voucherId = data.id;
        this.actionType = 3;
        if (this.$refs.VDA) {
          this.$refs.VDA.visible = true;
        }
      } else if (id === "print") {
        this.popPrint(data);
      } else if (id === "delete") {
        this.deleteConfirm(data, callback);
      } else if (id === "copy") {
        // 复制凭证 来源有可能是分录（列表）视图， 有可能是凭证详情
        let voucherId;
        let vData;
        if (data.voucherData && data.voucherData.id) {
          vData = data;
        } else if (!data.id) {
          return;
        } else {
          voucherId = data.id;
          const pa = { voucherId, vcType: 1 };
          const rsp = await voucherDetail.queryVoucherDetail(pa);
          // 先对数据进行处理 再缓存数据
          delete rsp.voucherData.id;
          delete rsp.voucherData.status;
          delete rsp.voucherData.voucherNo;
          delete rsp.voucherData.makeId;
          delete rsp.voucherData.makeName;
          delete rsp.voucherData.themeFileList;
          delete rsp.voucherData.attachmentNum;
          rsp.voucherHeader.forEach((a) => {
            if (a.fieldNameVariable === "voucherNo" || a.fieldNameVariable === "status") {
              delete a.value;
            }
          });
          vData = rsp;
        }
        this.setCacheVoucherData(vData);
        this.actionType = 4;
        this.voucherId = "";
        this.setProps && this.setProps();
        if (this.$refs.VDA) {
          this.$refs.VDA.visible = true;
        }
        callback && callback();
      } else if (id === "charge") {
        // 冲销凭证
        this.actionType = 5;
        this.voucherId = data.id;
        this.setProps && this.setProps();
        if (this.$refs.VDA) {
          this.$refs.VDA.visible = true;
        }
        callback && callback();
      } else if (id === "check") {
        // 审核（凭证审核用）
        this.handleVouOperate(data, 1);
      } else if (id === "cancelCheck") {
        // 取消审核（凭证审核用）
        this.handleVouOperate(data, 2);
      } else if (id === "submit") {
        // 提交单条
        this.submitVoucher([data]);
      } else if (id.includes("custom")) {
        // 自定义按钮
        const config = this.selfBtn.filter((itm) => itm.btnKey === id)[0];
        this.emitCustomBtn(config, data.id);
      }
    },
    /**
     * @description: 复制凭证预处理 将被复制凭证详情 缓存
     */
    async beforeCopy(data) {
      let voucherId;
      let vData;
      if (data.voucherData && data.voucherData.id) {
        vData = data;
      } else if (!data.id) {
        return;
      } else {
        voucherId = data.id;
        const pa = { voucherId, vcType: 1 };
        const rsp = await voucherDetail.queryVoucherDetail(pa);
        // 先对数据进行处理 再缓存数据
        delete rsp.voucherData.id;
        delete rsp.voucherData.status;
        delete rsp.voucherData.voucherNo;
        delete rsp.voucherData.makeId;
        delete rsp.voucherData.makeName;
        delete rsp.voucherData.themeFileList;
        delete rsp.voucherData.attachmentNum;
        rsp.voucherHeader.forEach((a) => {
          if (a.fieldNameVariable === "voucherNo" || a.fieldNameVariable === "status") {
            delete a.value;
          }
        });
        vData = rsp;
      }
      this.setCacheVoucherData(vData);
    },
    /**
     * @description: 打印设置
     * @param data 单条数据打印
     */
    popPrint(data) {
      // 找到选中 的凭证 ；
      if (data) {
        this.$refs.popPrint.voucherList = _.cloneDeep([data]);
      } else {
        const selected = this.voucherData.filter((item) => item.checked);
        if (selected.length) {
          const extra = selected.filter((itm) => !["000", "001"].includes(itm.status));
          if (extra.length) {
            Message("打印的凭证状态须为“待审核”或“已审核”");
            return;
          }

          this.$refs.popPrint.voucherList = _.cloneDeep(selected);
        } else {
          return;
        }
      }
      this.$refs.popPrint.visible = true;
    },
    /**
     * @description: 整理断号
     */
    async brokenConfirm() {
      const { params, dateLineParam } = this;
      const _this = this;
      if (!params.accountingEntity) {
        Message("请选择记账主体");
        return;
      }
      if (!params.accountingPeriod) {
        Message("请选择会计期间");
        return;
      }
      if (params.accountingPeriod.indexOf(",") > -1) {
        // 选择了多个会计期间
        Message("请选择一个会计期间进行断号整理");
        return;
      }

      const pa = {
        accountingPeriod: params.accountingPeriod,
        accountId: params.accountId,
        accountingEntity: params.accountingEntity,
        accountingPeriodMainId: dateLineParam.accountingPeriodMainId || ""
      };
      const vali = await intelligentService.validateBroken(pa);

      if (vali) {
        Message("该会计期间已经结账，不能再进行断号整理");
        return;
      }

      MessageBox.confirm(`确定进行${this.dateLineParam.accountingPeriodName}会计期间断号整理?`, "", {
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        type: "info",
        customClass: "dh-confirm"
      }).then(async() => {
        await intelligentService.brokenSign(pa);
        _this.queryVoucherList();
      });
    },
    /**
     * @description: 删除凭证二次确认
     * @param data  凭证数据
     */
    deleteConfirm(data, callback) {
      this.$confirm("确定删除?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.handleVouOperate(data, 3, callback);
      });
    },
    /**
     * @description: 获取选中数据
     * @param data 凭证数据(有data为单条数据操作，无为批量操作)
     * @param type 1:审核, 2:取消审核, 3:删除
     * @param callback  回调
     */
    async handleVouOperate(data, type, callback) {
      let dd = [];
      const ids = [];
      const _this = this;
      let flg = false;
      if (data) {
        dd.push(data);
      } else {
        dd = this.getCheckedData();
      }

      dd.forEach((v) => {
        ids.push(v.id);
        if ((v.status !== "000" && type === 1)
            || (v.status !== "001" && type === 2)
            || (v.status === "001" && type === 3)
        ) {
          flg = true;
        }
      });
      if (flg) {
        let msg = "";
        switch (type) {
          case 1:
            msg = "勾选的凭证状态须为“待审核”";
            break;
          case 2:
            msg = "勾选的凭证状态须为“已审核”";
            break;
          case 3:
            msg = "勾选的凭证状态须为“待审核”或“未提交”或“生成异常”";
            break;
          default:
            break;
        }
        Message({
          type: "warning",
          message: msg
        });
        return;
      }

      const rsp = await intelligentService.operateVoucher({ voucherIds: ids.join(","), operateType: type });
      const rspMsg = `成功${type === 1 ? "审核" : type === 2 ? "取消审核" : "删除"}凭证${rsp.successNum || 0}条，失败${rsp.failNum}条`;
      Message({
        type: "success",
        message: rspMsg,
        duration: 2000,
        onClose: () => {
          if (callback) {
            callback();
          } else {
            _this.queryVoucherList();
          }
        }
      });
    },
    /**
     * @description: 触发自定义 按钮
     * @param config 按钮配置
     * @param ids 数据id
     * @param isDetail 是否是详情页
     */
    async emitCustomBtn(config, ids) {
      const _this = this;
      const { btnKey, jumpPage } = config;
      await intelligentService.emitCustomBtn({ btnKey, ids });
      Message({
        message: "操作成功",
        onClose() {
          if (jumpPage === 1) { // 跳转列表

          } else if (jumpPage === 2 && !ids.includes(",")) { // 跳转详情
            _this.setProps && _this.setProps();
            _this.voucherId = ids;
             _this.actionType = 2;
            if (_this.$refs.VDA) {
              _this.$refs.VDA.visible = true;
            }
          }
        }
      });
    },
    /**
     * @description: 导出
     */
    async exportVoucher() {
      this.$showLoading();
      const { accountId, accountingEntity } = this.params;
      const self = {
        accountName: (() => {
          let n = "";
          this.accList.forEach((itm) => {
            if (itm.id === accountId) {
              n = itm.name;
            }
          });
          return n;
        })(),
        accountingEntityName: (() => {
          let n = "";
          this.entityList.forEach((itm) => {
            if (itm.id === accountingEntity) {
              n = `${itm.code} ${itm.name}`;
            }
            (itm.nodes || []).forEach((citm) => {
              if (citm.id === accountingEntity) {
                n = `${citm.code} ${citm.name}`;
              }
            });
          });
          return n;
        })(),
        accountingPeriodName: this.dateLineParam.accountingPeriodName,
        count: this.pageModel.total
      };
      const obj = { ...self, ...this.params };
      obj.conditions = typeof (obj.conditions) === "string" ? obj.conditions : JSON.stringify(obj.conditions);
      obj.sortFields = typeof (obj.sortFields) === "string" ? obj.sortFields : JSON.stringify(obj.sortFields);
      obj.filterFields = typeof (obj.filterFields) === "string" ? obj.filterFields : JSON.stringify(obj.filterFields);
      obj.conditions = base64Encode(obj.conditions);
      obj.sortFields = base64Encode(obj.sortFields);
      obj.filterFields = base64Encode(obj.filterFields);

      const res = await intelligentService.exportVoucher(obj);
      this.$hideLoading();
      const { headers } = res;
      const cd = headers["content-disposition"] || "";
      const url = window.URL.createObjectURL(new Blob([res.data], { type: "application/vnd.ms-excel" }));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.download = decodeURIComponent(cd.split("=")[1]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
