<!--
 * @Author: pengyu
 * @Date: 2020-12-24 18:02:39
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-28 17:21:21
 * @Description: 凭证弹框 （新增，查看，编辑，复制，冲销）
-->
<template>
  <el-dialog
    class="voc-detail-alert"
    fullscreen
    append-to-body
    :visible="visible"
    @close="visible=false"
  >
    <div slot="title" class="title-wrap">
      <div class="left">{{title}}</div>
      <div class="right">
        <status-model v-if="operationType===5" status="004"></status-model>
        <status-model v-else :status="vcdata.voucherData.status"></status-model>
        <el-dropdown @command="handleCommand" v-if="operationType!==5">
          <span class="el-dropdown-link">
            <en-button
              type="text"
              icon="shoucang-kuaijiecaozuo"
              :icon-size="16"
              icon-color="#F8C641"
            > 收藏 </en-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="cj">
              创建
            </el-dropdown-item>
            <el-dropdown-item command="yy">
              引用
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="voc-detail-wrap" v-en-loading="enLoading">
      <!-- 新增凭证固定头部 -->
      <div class="top-row" v-if="operationType===1">
        <div class="top-row-item">
          <span><span>所属账套：</span><span>{{vcHeader.accountName}}</span></span>
        </div>
        <div class="top-row-item">
          <span><span>记账主体：</span><span>{{vcHeader.accountingEntityName}}</span></span>
        </div>
        <div class="top-row-item">
          <span><span>会计期间：</span><span>{{vcHeader.accountingPeriodName}}</span></span>
        </div>
        <div class="top-row-item">
          <span>
            <span>记账日期：</span>
            <en-date-picker
              type="date"
              v-model="vcHeader.produceDate"
              placeholder="选择日期时间"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="produceDateChange"
            >
            </en-date-picker>
          </span>
        </div>
        <div class="top-row-item">
          <span><span>凭证字号：</span><span>自动生成</span></span>
        </div>
        <div class="top-row-item">
          <span><span>凭证来源：</span><span>无</span></span>
        </div>
      </div>
      <!-- 冲销凭证固定头部 -->
      <div class="top-row" v-else-if="operationType===5">
        <div class="top-row-item">
          <span><span>凭证字号：</span><span>自动生成</span></span>
        </div>
        <div class="top-row-item">
          <span><span>凭证来源：</span><span>{{chargeVoucherSourceName}}</span></span>
        </div>
        <div class="top-row-item">
          <span><span>所属账套：</span><span>{{vcHeader.accountName}}</span></span>
        </div>
        <div class="top-row-item">
          <span><span>记账主体：</span><span>{{vcHeader.accountingEntityName}}</span></span>
        </div>
        <div class="top-row-item">
          <span>
            <span>记账日期：</span>
            <el-date-picker
              type="date"
              v-model="vcHeader.produceDate"
              placeholder="选择日期时间"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="produceDateChange"
            >
            </el-date-picker>
          </span>
        </div>
        <div class="top-row-item">
          <span><span>会计期间：</span><span>{{vcHeader.accountingPeriodName}}</span></span>
        </div>
      </div>
      <!-- 详情 查询头部 -->
      <div class="top-row" v-else>
        <div :span="4" v-for="(item,index) in vcdata.voucherHeader" :key="index"  class="top-row-item">
          <span v-if="item.fieldNameVariable==='voucherNo'">
            <!-- 凭证号 -->
            <span>凭证字号：</span>
            <span v-if="operationType===2">{{formatVoucherNo(item.value)}}</span>
            <div v-else-if="item.value && operationType !== 2" style="display: inline-block">
              <span>{{`${vcdata.voucherData.voucherDataTypeName}-`}}</span>
              <el-input
                type="number"
                v-model="item.value"
              ></el-input>
            </div>
            <span v-else>自动生成</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='refId'">
            <!-- 凭证来源 -->
            <span>{{item.fieldName}}：</span>
            <span :class="getRefIdName(item.value) && getRefIdName(item.value)!=='无' ? 'through' : ''"
                  @click="throughVoucher(item)">{{ getRefIdName(item.value) }}</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='accountingEntity'">
            <!-- 记账主体 -->
            <span>{{item.fieldName}}：</span><span>{{ item.value.name }}</span>
          </span>
          <span v-else-if="item.fieldNameVariable==='accountingPeriod'">
            <!-- 会计期间 -->
            <span>{{item.fieldName}}：</span><span>{{ item.value.name }}</span>
          </span>
          <span v-else-if="item.type===7 && item.fieldNameVariable!=='accountingPeriod'">
            <span>{{item.fieldName}}：</span>
            <span v-if="operationType===2">{{item.value.slice(0, 10)}}</span>
            <el-date-picker
              v-else
              type="date"
              v-model="item.value"
              placeholder="选择日期时间"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @change="produceDateChange"
              :disabled="fromCarryOver"
            >
            </el-date-picker>
          </span>
          <span v-else-if="item.type===5">
            <span>{{item.fieldName}}：</span><span>{{ item.value&&item.value[0]&&item.value[0].name||"" }}</span>
          </span>
          <span v-else-if="[1,3,4].includes(item.type)">
            <span>{{item.fieldName}}：</span><span>{{ item.value }}</span>
          </span>
        </div>
      </div>
      <!-- 表格 -->
      <div class="grid-wrap">
        <voucher-table
          :isFor="vcdata.voucherData.isFor"
          :accountId="vcHeader.accountId"
          :subjectList="subjectList"
          :actionType="operationType"
          :voucherType="vcdata.voucherData.voucherType"
        ></voucher-table>
      </div>
      <!-- 制单人审核人 -->
      <div :class="['middle-row', operationType===2?'grey':'']">
        <div>制单人： {{ vcdata.voucherData.makeName || userInfo.name }}</div>
        <div>审核人： {{ vcdata.voucherData.approveName || ""}}</div>
        <div v-if="operationType===2">
          <span>附单据：{{attachmentNum}} 张</span>
        </div>
        <div v-else>
          <span>附单据</span>
          <el-input
            v-model="attachmentNum"
            type="number"
            placeholder="请输入"
          ></el-input>
          <span class="suffix">张</span>
        </div>
      </div>
      <!--  附件与图片 -->
      <div class="bottom-row">
        <div class="col-left">
          <span>附件与图片</span>
          <el-upload
            v-if="operationType!==2"
            multiple
            class="foot-upload"
            :accept="upextStr"
            :data="uploadParams"
            name="storage_file"
            :action="urlParam.server"
            :on-success="handleUpload"
            :show-file-list="false"
          >
            <en-icon
              name="tianjia-danchuang"
              color="#4894DF"
              size="14"
            ></en-icon>
          </el-upload>
        </div>
        <div class="col-right">
          <en-icon
            v-show="showCashBtn"
            name="jiezhuanjinetongji"
            color="#24C494"
            size="normal"
            title="现金流量项目"
            @click.native="setCashFlowItem"
          ></en-icon>
        </div>
      </div>
      <!-- 附件 列表 -->
      <div class="file-row">
        <div class="img-lis">
          <img-li
            v-for="(item, index) in imageList"
            :key="index"
            :attr="item"
            :index="index"
            :disable="operationType===2"
            @img-close="imageClose"
          ></img-li>
        </div>
        <div class="file-lis">
          <file-li
            v-for="(item, index) in fileList"
            :key="index"
            :attr="item"
            :index="index"
            :disable="operationType===2"
            @file-close="fileClose"
          ></file-li>
        </div>
      </div>
      <!-- 操作按钮 -->
      <div class="btn-row">
        <en-button
          v-for="(item,idx) in btnList"
          :key="idx"
          :type="item.type"
          :title="item.name"
          @click="voucherBtnClick(item)"
        >
          {{ item.name }}
        </en-button>
      </div>
      <!-- 左右切换凭证按钮 -->
      <div
        v-if="operationType===2 && voucherIdAry.length > 0"
        class="btn-switch btn-left-voc"
        :class="voucherIdAry.indexOf(detailId) === 0 ? 'disabled' : ''"
        @click="switchVoucherDetail('prev')"
      >
        <en-icon
          name="tupian-zuojiantou"
          size="small"
        ></en-icon>
      </div>
      <div
        v-if="operationType===2 && voucherIdAry.length > 0"
        class="btn-switch btn-right-voc"
        :class="voucherIdAry.indexOf(detailId) === (voucherIdAry.length - 1) ? 'disabled' : ''"
        @click="switchVoucherDetail('next')"
      >
        <en-icon
          name="tupian-youjiantou"
          size="small"
        ></en-icon>
      </div>
    </div>
    <!-- 创建模板 -->
    <create-model
      ref="popCreateModel"
      :itemList="modelItemList"
      :yearNum="vcHeader.yearNum"
      :accountId="vcHeader.accountId"
    ></create-model>
    <!-- 引用模板 -->
    <quote-model
      ref="popQuoteModel"
      :accountId="vcHeader.accountId"
      :yearNum="vcHeader.yearNum"
      @handleChooseModel="handleQuoteModel"
    ></quote-model>
    <!-- 打印 -->
    <print-alert ref="popPrint"></print-alert>
    <!-- 现金流量项目 -->
    <check-cash-flow
      ref="cashFlowMod"
      :itemList="submitParam.itemList"
      :operationType="operationType"
      @handleCashFlowSubmit="cashFlowSubmit"
    ></check-cash-flow>
    <!-- 操作日志 -->
    <log-alert ref="popLog" :logParam="logParam"></log-alert>
    <!-- 业务单据列表 -->
    <business-grid ref="popBusGrid" :busTemplateId="busTemplateId" :sourceId="sourceId"></business-grid>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { MessageBox, Message } from "element-ui";
import _ from "lodash";
import { executeNum } from "en-js";
import dayjs from "dayjs";
import voucherButtonEvent from "@/mixins/intelligentAccounting/voucherButtonEvent";// 按钮公共方法
import { voucherDetail, intelligentService } from "@/api";
import { getFileUrl } from "@/tools/getFileUrl";
import getSingleBtnList from "@/mixins/intelligentAccounting/getSingleBtnList"; // 获取按钮
import VoucherTable from "../voucherTable";
import StatusModel from "../statusModel";// 状态块块
import CreateModel from "../createModel"; // 创建模板弹窗
import QuoteModel from "../quoteModel"; // 引用模板弹窗
import PrintAlert from "../printSetting"; // 打印设置
import ImgLi from "../imgLi/imgLi"; // 图片块块
import FileLi from "../fileLi/fileLi"; // 文件块块
import CheckCashFlow from "../checkCashFlow";
import LogAlert from "../logAlert"; // 操作日志'
import BusinessGrid from "../businessGrid";

// 现金流量项目
export default {
  name: "VocDetailAlert",
  components: {
    VoucherTable,
    ImgLi,
    FileLi,
    CreateModel,
    QuoteModel,
    PrintAlert,
    StatusModel,
    CheckCashFlow,
    LogAlert,
    BusinessGrid
  },
  provide() {
    return {
      getItemList: () => this.vcdata.itemList,
      getVcHeader: () => this.vcHeader
    };
  },
  mixins: [voucherButtonEvent, getSingleBtnList],
  props: {
    actionType: Number, // 1新增2查看3编辑4复制5冲销
    voucherId: String, // 凭证id
    cache: { // 是否使用缓存数据
      type: Boolean,
      default: false
    },
    isCheckPage: { // 是否来源凭证审核
      type: Boolean,
      default: false
    },
    formal: {
      type: Object,
      default: () => {}
    },
    fromCarryOver: { // 来源于结转
      type: Boolean,
      default: false
    },
    fromCredential: { // 来源凭证生成 异常凭证
      type: Boolean,
      default: false
    },
    refreshFunc: { // 父级刷新方法
      type: String,
      default: ""
    },
    voucherIdAry: { // 用于凭证详情切换
      type: Array,
      default: () => []
    }
  },
  computed: {
    title() {
      const titleMap = {
        1: "新增凭证",
        2: "凭证详情",
        3: "编辑凭证",
        4: "复制凭证",
        5: "冲销凭证"
      };
      return titleMap[this.operationType];
      // let str = "";
      // switch (this.operationType) {
      //   case 1:
      //     str = "新增凭证"; break;
      //   case 2:
      //     str = "凭证详情"; break;
      //   case 3:
      //     str = "编辑凭证"; break;
      //   case 4:
      //     str = "复制凭证"; break;
      //   case 5:
      //     str = "冲销凭证"; break;
      // }
      // return str;
    },
    ...mapGetters(["userInfo", "urlParam"]),
    ...mapGetters("intelligentAccounting", {
      cacheData: "getCacheVoucherData"
    }),
    upextStr() {
      return this.upextArr.join(",");
    }
  },
  data() {
    return {
      detailId: "", // 凭证id
      operationType: 0,
      visible: false,
      enLoading: false,
      vcHeader: {
        accountId: "", // 账套id
        accountName: "",
        accountingEntity: "", // 记账主体id
        accountingEntityName: "",
        produceDate: "", // 记账日期
        yearNum: "",
        accountingPeriod: "", // 会计期间
        accountingPeriodName: "",
        accountingPeriodMainId: "",
        vcTemplateId: ""
      },
      pickerOptions: {}, // 设置记账日期控件的选项设置
      vcdata: {
        itemList: [], // 分录行数据
        voucherHeader: [],
        voucherData: {}
      },
      commonData: {
        remark: "", // 摘要
        subjectId: "", // 科目id
        amountD: "", // 借方本位币
        amountC: "", // 贷方本位币
        foroCurrAmountD: "", // 借方原币金额
        foroCurrAmountC: "", // 贷方原币金额
        amount: "", // 原币金额
        quantityAcc: "", // 数量
        foroCurrSys: "", // 币种
        foroCurrSysName: "", // 币种name
        price: "", // 单价
        rate: "", // 汇率
        fzhs: [] // 辅助核算
      }, // 单条分录
      subjectList: [], // 科目list
      subjectMap: {}, // 科目map
      subjectProps: { value: "id", label: "formatText" },
      fzhsBaseData: {}, // 辅助核算对应的基础数据
      uploadParams: {}, // 上传参数
      imageList: [], // 上传图片集合
      fileList: [], // 上传文件集合
      btnList: [], // 按钮集合
      bUrl: "", // 返回路径
      VOUCHER_RULE_LENDING_BALANCE: "1", // 是否需要借贷平衡
      modelItemList: [], // 创建模板使用 的分录行数据
      upextArr: [".gif", ".jpg", ".jpeg", ".bmp", ".png", ".pdf", ".doc", ".docx", ".txt", ".xls", ".xlsx", ".ppt", ".pptx", ".zip", ".rar"], // 上传文件类型限制
      imgArr: ["gif", "jpg", "jpeg", "bmp", "png"], // 图片类型
      fileArr: ["pdf", "doc", "docx", "txt", "xls", "xlsx", "ppt", "pptx", "rar", "zip"], // 文件类型
      attachmentNum: 0, // 单据数量
      submitParam: {}, // 提交参数
      busTemplateId: "", // 业务模板id
      sourceId: "", // 业务数据id
      isCharged: true, // 是否已经冲销
      showCashBtn: false, // 是否显示现金流量科目设置按钮
      cashProjectIsBalance: true,
      logParam: {}, // 日志查询参数
      chargeVoucherSourceName: ""
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.initPage();
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    },
    "vcdata.itemList": {
      deep: true,
      handler() {
        this.checkShowCashBtn();
      }
    }
  },
  methods: {
    /**
     * @description: 请求凭证详情
     */
    async initPage() {
      try {
        this.resetData();
        this.operationType = Number(this.actionType);
        const atp = this.operationType;
        Object.keys(this.vcHeader).forEach((itm) => {
          this.vcHeader[itm] = this.formal[itm] || "";
        });

        if (atp !== 2) { // 不为查看
          await this.queryComConfig();
        }

        if (atp === 1) { // 新增
          this.vcdata.itemList.push(JSON.parse(JSON.stringify(this.commonData)), {});
          await this.initProduceDate();
        } else if (atp === 2) { // 查看
          await this.queryVoucherDetail();
        } else { // 编辑复制冲销
          await this.queryVoucherDetail();
          await this.initProduceDate();
        }
        this.enLoading = false;
        this.renderBtn();
        this.uploadParams = {
          appKey: this.urlParam.appKey,
          token: this.urlParam.token,
          ts: this.urlParam.ts
        };
      } catch (error) {
        this.enLoading = false;
      }
    },
    /**
     * @description: 重置data数据
     */
    resetData() {
      Object.assign(this.$data, this.$options.data());
      this.visible = true;
      this.enLoading = true;
      this.detailId = this.voucherId;
    },
    /**
     * @description: 凭证字号  v4.8.10
     */
    formatVoucherNo(value) {
      const _no = value.toString().padStart(4, "0");
      return `${this.vcdata.voucherData.voucherDataTypeName || ""}-${_no}`;
    },
    /**
     * @description: 查询是否需要借贷平衡
     */
    async queryComConfig() {
      const rsp = await voucherDetail.queryComConfig();
      this.VOUCHER_RULE_LENDING_BALANCE = rsp ? rsp.VOUCHER_RULE_LENDING_BALANCE : "1";
    },
    /**
     * @description:  选择记账日期的时候，回填会计期间
     *                查询会计期间
     */
    async initProduceDate() {
      const pa = { accountId: this.vcHeader.accountId, accountingEntity: this.vcHeader.accountingEntity };
      const rsp = await voucherDetail.queryProduceDate(pa);
      if (this.operationType === 1) {
        this.vcHeader.produceDate = rsp.defaultTime.substring(0, 10);
      } else {
        const pd = this.vcdata.voucherHeader.filter((itm) => itm.fieldNameVariable === "produceDate");
        this.vcHeader.produceDate = pd[0]?.value.substring(0, 10);
      }
      this.pickerOptions.disabledDate = (time) => time.getTime() < new Date(rsp.startTime).getTime() || time.getTime() >= new Date(rsp.endTime).getTime();
      this.produceDateChange(this.vcHeader.produceDate, true);
    },
    /**
     * @description: 记账日期改变 回调
     * @param value
     */
    async produceDateChange(value, isInit) {
      // 选择记账日期的时候，回填会计期间
      // 查询会计期间
      if (!value) return;
      const { vcHeader } = this;
      const dd = dayjs(value).format("YYYY-MM-DD");
      vcHeader.produceDate = dd;

      const pa = { appointTime: `${dd} 00:00:00` };
      const rsp = await voucherDetail.queryAccTimeDetByTime(pa);

      if (vcHeader.yearNum !== rsp.yearNum) {
        if (!vcHeader.yearNum || isInit) {
          vcHeader.accountingPeriod = rsp.id;
          vcHeader.accountingPeriodName = rsp.name;
          vcHeader.yearNum = rsp.yearNum;
          vcHeader.accountingPeriodMainId = rsp.objectId;
          await this.querySubject();
        } else {
          // 更改会计年度，需要清除分录行
          this.changeYearNumConfirm(rsp);
        }
      } else {
        // 没有更改会计年度
        // eslint-disable-next-line no-lonely-if
        if (vcHeader.accountingPeriodName) { // 改了会计期间
          vcHeader.accountingPeriod = rsp.id;
          vcHeader.accountingPeriodName = rsp.name;
          vcHeader.accountingPeriodMainId = rsp.objectId;
        }
      }
    },
    /**
     * @description: 改了会计年度，需要清除分录行 确认
     */
    changeYearNumConfirm(rsp) {
      const _this = this;
      MessageBox.confirm("修改记账日期导致所属会计年度不同，将自动删除分录区科目", "提示信息", {
        showCancelButton: false,
        confirmButtonText: "确定",
        type: "warning"
      }).then(async() => {
        _this.vcdata.itemList = [];
        _this.vcdata.itemList.push(JSON.parse(JSON.stringify(this.commonData)), {}, {});
        _this.vcHeader.accountingPeriodName = rsp.name;
        _this.vcHeader.yearNum = rsp.yearNum;
        await this.querySubject();
      });
    },
    /**
     * @description: 查询凭证详情
     */
    async queryVoucherDetail() {
      let rsp;
      const { operationType } = this;
      this.enLoading = true;
      if (this.detailId) {
        const pa = { vcType: operationType === 5 ? 2 : 1, voucherId: this.detailId };
        rsp = await voucherDetail.queryVoucherDetail(pa);
      } else if (operationType === 4 || this.fromCarryOver) {
        // 来源复制凭证 和 结转 测算凭证 取缓存数据
        rsp = _.clone(this.cacheData);
        this.$store.commit("intelligentAccounting/_MxChangeVuexState", { cacheVoucherData: null });
      }

      if (operationType === 5) {
        this.chargeVoucherSourceName = `冲销${ rsp.voucherData.voucherNo}`;
      }

      this.enLoading = false;

      // 编辑 复制凭证的情况下 此时如果 分录行中有选了  含辅助核算和外币的科目  需要处理一下数据
      const _this = this;
      if (operationType === 3 || operationType === 4) {
        rsp.itemList?.forEach((itm) => {
          if (itm.fzhs) {
            const acc = [];
            const fzhsbd = [];
            itm.fzhs?.forEach(async(fh) => {
              acc.push(fh.key);
              let dd;
              if (_this.fzhsBaseData[fh.key]) {
                dd = _this.fzhsBaseData[fh.key];
                fzhsbd.push(dd);
              } else {
                const re = await voucherDetail.queryObjectTypeByIds({ ids: fh.key });
                re[0].value = "";
                dd = re[0];
                _this.fzhsBaseData[fh.key] = dd;
                fzhsbd.push(dd);
              }
            });
            itm.assitAcc = acc.toString();
            itm.fzhsbd = fzhsbd;
          }
          itm.forcoinAcc = itm.foroCurrSys;
          itm.forcoinAccName = itm.foroCurrSysName;
          itm.quantityUnit = itm.quantityAccunit;
          itm.quantityUnitName = itm.quantityAccunitName;
          itm.amount = itm.amount || itm.foroCurrAmountD || itm.foroCurrAmountC;
        });
      } else if (operationType === 5) {
        let isCharge = true;
        rsp.itemList.forEach((itm) => {
          if (Number(itm.amountD) !== 0 || Number(itm.amountC) !== 0) {
            isCharge = false;
          }
          // 处理摘要信息
          const pd = new Date(rsp.voucherData.produceDate);
          itm.remark = `冲销${pd.getFullYear()}年${pd.getMonth() + 1}月${pd.getDate()}日${rsp.voucherData.voucherNo}号凭证`;
        });
        rsp.voucherData.voucherNo = "";
        this.isCharged = isCharge;
        if (isCharge) {
          Message("金额已全部冲销，不能再进行冲销");
          rsp.itemList = [];
        }
      }
      // 头部不展示状态 凭证类别
      rsp.voucherHeader = rsp.voucherHeader.filter((itm) => (!["status", "voucherDataType"].includes(itm.fieldNameVariable)));

      if (operationType === 5) {
        rsp.itemList?.forEach((itm) => {
          itm.amountD = itm.amountD ? (0 - Number(itm.amountD)) : "";
          itm.amountC = itm.amountC ? (0 - Number(itm.amountC)) : "";
        });
      }

      this.vcdata = { ...rsp };
      this.vcdata.itemList.push({});
      this.attachmentNum = rsp.voucherData.attachmentNum;

      // 附件与图片
      this.vcdata.voucherData?.themeFileList?.forEach((itm) => {
        itm.src = getFileUrl(itm.code, itm.type, itm.name);
        if (itm.type === "000" || itm.type === 0) {
          this.imageList.push(itm);
        } else {
          this.fileList.push(itm);
        }
      });
      Object.keys(this.vcHeader).forEach((itm) => {
        this.vcHeader[itm] = this.vcdata.voucherData[itm] || "";
      });

      const acc = rsp.voucherHeader?.find((itm) => itm.fieldNameVariable === "accountingPeriod");
      if (acc && acc.value) {
        this.vcHeader.yearNum = acc.value.yearNum;
        this.vcHeader.accountingPeriodMainId = acc.value.accountingPeriodMainId;
      }
    },
    /**
     * @description: 查询完凭证详情后获取按钮
     */
    renderBtn() {
      let needBtns = this.getDetailBtnList(this.operationType, this.vcdata.voucherData, this.isCheckPage);

      if (this.operationType === 5 && this.isCharged) {
        // 已经冲销过了 去除暂存和提交
        needBtns = needBtns.filter((itm) => !["save", "submit"].includes(itm.id));
      }
      if (this.fromCarryOver) {
        // 来源 结转 去除继续添加日志打印
        needBtns = needBtns.filter((itm) => !["continueAdd", "log", "print"].includes(itm.id));
      }
      if (this.fromCredential) {
        // 来源 结异常凭证 去除继续添加
        needBtns = needBtns.filter((itm) => !["continueAdd"].includes(itm.id));
      }
      if ([4, 5].includes(this.operationType)) {
        // 复制 冲销页面去除删除打印日志按钮 v4.8.8
        needBtns = needBtns.filter((itm) => !["delete", "print", "log"].includes(itm.id));
      }

      // 查看详情 增加自定义按钮
      const selfBtn = [];
      if (this.operationType === 2) {
        this.vcdata?.voucherData?.customBtnList?.forEach((itm) => {
          const citm = _.clone(itm);
          citm.id = citm.btnKey;
          citm.name = citm.btnName;
          citm.type = "white";
          selfBtn.push(citm);
        });
      }

      this.btnList = [...needBtns, ...selfBtn];
    },
    /**
     * @description: 获取凭证头部值
     * @param item
     * @return:
     */
    getHeader(item) {
      const { value } = item;
      let re = "";
      if (_.isString(value)) {
        if (item.fieldNameVariable === "voucherNo") {
          re = value ? "" : "自动生成";
        }
      } else if (_.isObject(value)) {
        re = value.name;
      } else if (_.isArray(value)) {
        return value.forEach((a) => {
          re += a.name;
        });
      }
      return re;
    },
    /**
     * @description: 收藏按钮
     * @param command 创建cj  引用yy
     */
    handleCommand(command) {
      if (command === "cj") {
        const re = this.getRowData();
        if (re) {
          this.modelItemList = JSON.parse(JSON.stringify(re));
          this.$refs.popCreateModel.visible = true;
        }
      } else if (command === "yy") {
        this.$refs.popQuoteModel.visible = true;
      }
    },
    /**
     * @description: 查询年度下的所有科目
     */
    async querySubject() {
      const pa = { accountId: this.vcHeader.accountId, yearNum: this.vcHeader.yearNum };
      if (!this.subjectMap[this.vcHeader.yearNum]) {
        const rsp = await voucherDetail.querySubjectNoParent(pa);
        rsp.forEach((item) => {
          item.formatText = `${item.code} ${item.name}`;
        });

        this.subjectMap[this.vcHeader.yearNum] = rsp;
        this.subjectList = rsp;
      }
    },
    /**
     * @description: 删除图片
     * @param index
     */
    imageClose(index) {
      this.imageList.splice(index, 1);
      this.attachmentNum = Number(this.attachmentNum) - 1;
    },
    /**
     * @description: 删除图片
     * @param index
     */
    fileClose(index) {
      this.fileList.splice(index, 1);
      this.attachmentNum = Number(this.attachmentNum) - 1;
    },
    /**
     * @description: 保存
     * @param type 1暂存 2提交 3继续添加
     */
    save(type) {
      const rd = this.getRowData(type);
      if (!rd) {
        return;
      }
      const { vcHeader, operationType } = this;
      if (!vcHeader.accountingPeriod && operationType !== 1) {
        Message("记账日期未维护会计期间");
        return;
      }
      if (!vcHeader.accountingEntity) {
        Message("请选择记账主体");
        return;
      }

      const { voucherData } = this.vcdata;
      let params;
      if (this.operationType === 5) {
        params = {
          operType: type,
          itemList: rd,
          themeFileList: [...this.fileList, ...this.imageList],
          ...this.vcdata.voucherData,
          refCode: this.chargeVoucherSourceName,
          attachmentNum: this.attachmentNum || "",
          produceDate: dayjs(vcHeader.produceDate).format("YYYY-MM-DD"),
          accountingPeriod: vcHeader.accountingPeriod,
          accountingPeriodMainId: vcHeader.accountingPeriodMainId
        };
        delete params.customBtnList;
      } else {
        params = {
          operateType: type,
          itemList: rd,
          themeFileList: [...this.fileList, ...this.imageList],
          voucherMain: {
            id: this.detailId || "", //   string 凭证ID
            voucherNo: voucherData.voucherNo || "", // string 凭证号
            refId: voucherData.refId || "", // string 凭证来源ID—查询和冲销用
            refCode: voucherData.refCode || "", // string 凭证来源编码—查询和冲销用
            accountId: vcHeader.accountId,
            accountName: vcHeader.accountName,
            produceDate: dayjs(vcHeader.produceDate).format("YYYY-MM-DD"),
            accountingPeriod: vcHeader.accountingPeriod,
            accountingPeriodName: vcHeader.accountingPeriodName,
            accountingPeriodMainId: vcHeader.accountingPeriodMainId,
            attachmentNum: this.attachmentNum || "",
            accountingEntity: vcHeader.accountingEntity,
            accountingEntityName: vcHeader.accountingEntityName,
            vcTemplateId: vcHeader.vcTemplateId || ""
          }
        };
      }

      let sumD = 0; let sumC = 0;
      rd.forEach((itm) => {
        sumD += Number(itm.amountD);
        sumC += Number(itm.amountC);
      });
      console.log(executeNum(sumD, sumC, 1, 2));
      console.log(typeof (executeNum(sumD, sumC, 1, 2)));
      if (type !== 1 && this.VOUCHER_RULE_LENDING_BALANCE === "0" && (executeNum(sumD, sumC, 1, 2) !== 0)) {
        Message("借贷合计金额不相等");
        return;
      }
      // 现金流量科目借贷不平衡
      if (!this.cashProjectIsBalance && type !== 1) {
        this.checkLending(params, type);
      } else {
        this.submitVoucher(params, type);
      }
    },
    /**
     * @description: 校验现金流科目 借贷平衡
     * @param param  提交数据
     * @param type  保存类型
     * @return:
     */
    checkLending(param, type) {
      let totalC = 0;
      let totalD = 0;
      let has = false;
      const list = param.itemList;
      list?.forEach((itm) => {
        if (itm.cashSubject === "1") { // 现金流科目
          totalC += itm.amountC ? Number(itm.amountC) : 0;
          totalD += itm.amountD ? Number(itm.amountD) : 0;
        } else { // 非现金流科目
          has = true;
        }
      });

      if (has && totalC !== totalD) { // 现金流量科目借贷不平衡
        this.submitParam = param;
        this.$refs.cashFlowMod.visible = true;
      } else {
        this.submitVoucher(param, type);
      }
    },
    /**
     * @description: 提交凭证数据
     * @param param 提交数据
     * @param type 保存类型 1暂存 2提交 3继续添加
     * @return:
     */
    async submitVoucher(param, type) {
      const _this = this;
      param.itemList = JSON.stringify(param.itemList);
      param.voucherMain = JSON.stringify(param.voucherMain);
      param.themeFileList = JSON.stringify(param.themeFileList);
      try {
        if (_this.operationType === 5) {
          await voucherDetail.chargeVoucher(param);
        } else {
          await voucherDetail.saveOrUpdateVoucher(param);
        }

        Message({
          type: "success",
          message: type === 1 ? "暂存成功" : "提交成功",
          duration: 2000,
          onClose: () => {
            if (type === 3) { // 继续添加
              _this.initPage();
            } else {
              if (_this.refreshFunc) {
                _this.$parent[_this.refreshFunc]();
              }
              _this.visible = false;
            }
          }
        });
      } catch (error) {
        console.log(`%c${error}`, "color:red");
      }
    },
    /**
     * @description: 附件上传成功处理
     * @param response
     * @param file
     */
    handleUpload(response, file) {
      try {
        const { data } = response;
        const suffix = file.name.replace(/.+\./, "");
        const attr = {
          src: data.fileUrl,
          code: data.fileCode,
          extension: suffix,
          name: file.name,
          size: file.size
        };

        if (this.imgArr.includes(suffix)) { // 图片类型
          attr.type = "000";
          this.imageList.push(attr);
        } else if (this.fileArr.includes(suffix)) { // 文件类型
          attr.type = "001";
          this.fileList.push(attr);
        }
        this.attachmentNum = Number(this.attachmentNum) + 1;
      } catch (error) {
        console.log(`%c${error}`, "color:red");
      }
    },
    /**
     * @description: 底部按钮点击事件
     * @param item 按钮详情
     */
    async voucherBtnClick(item) {
      const { id } = item;
      const _this = this;
      if (id === "save") {
        this.save(1);
      } else if (id === "submit") {
        this.save(2);
      } else if (id === "continueAdd") {
        this.save(3);
      } else if (id === "edit") {
        this.operationType = 3;
        await this.queryVoucherDetail();
        this.renderBtn();
      } else if (id === "delete") {
        this.singleClick({
          id,
          data: this.vcdata.voucherData,
          callback() {
            if (_this.fromCarryOver || _this.fromCredential) {
              _this.visible = false;
              if (_this.refreshFunc) {
                _this.$parent[_this.refreshFunc]();
              }
            }
          }
        }, true);
      } else if (id === "log") {
        this.singleClick({
          id,
          data: this.vcdata.voucherData,
          isCheckPage: this.isCheckPage
        }, true);
      } else if (id === "print") {
        const list = _.cloneDeep(this.vcdata.itemList);
        // 最后1条数据是 用于表格补位的填充数据 去掉
        list.splice(list.length - 1, 1);
        this.singleClick({
          id,
          data: {
            ...this.vcdata.voucherData,
            itemList: list
          }
        }, true);
      } else if (id === "copy") {
        const _this = this;
        this.singleClick({
          id,
          data: {
            ...this.vcdata.voucherData,
            itemList: this.vcdata.itemList
          },
          callback() {
            _this.initPage();
          }
        }, true);
      } else if (id === "charge") {
        this.operationType = 5;
        await this.queryVoucherDetail();
        this.renderBtn();
      } else if (id.includes("custom")) { // 自定义按钮
        this.emitCustomBtn(id);
      }
      return false;
    },
    /**
     * @description: 校验itemList 合法性,组装分录数据
     * @param type   1暂存 2提交 3继续添加
     */
    getRowData(type) {
      const { itemList } = this.vcdata;
      let flg = false;
      const result = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const itm of itemList) {
        if (itm.remark && itm.subjectId && (itm.amountD || itm.amountC)) {
          let rd = {
            remark: itm.remark || "",
            subjectId: itm.subjectId || "",
            subjectCode: itm.subjectCode || "",
            subjectName: itm.subjectName || "",
            amountD: itm.amountD || "",
            amountC: itm.amountC || "",
            quantityAcc: itm.quantityAcc || "",
            price: itm.price || "",
            foroCurrSys: itm.foroCurrSys || "",
            foroCurrSysName: itm.foroCurrSysName || "",
            rate: itm.rate || "",
            cashSubject: itm.cashSubject || "",
            cCashPro: itm.cCashPro || "",
            dCashPro: itm.dCashPro || "",
            fzhs: itm.fzhs || []
          };
          rd.foroCurrAmountD = itm.amountD ? (itm.amount || "") : "";
          rd.foroCurrAmountC = itm.amountC ? (itm.amount || "") : "";

          if (itm.fzhs) {
            itm.fzhs?.forEach((fd) => {
              rd[fd.key] = fd.value;
            });
          }

          rd = Object.assign(itm, rd);
          if (rd.id && rd.id.includes("row")) {
            delete rd.id;
          }

          result.push(rd);
        } else if (!itm.remark && !itm.subjectId && !(itm.amountD && itm.amountC)) {
          // eslint-disable-next-line no-continue
          continue;
        } else {
          flg = true;
          break;
        }
      }
      if (type !== 1 && flg) {
        Message("请输入完整的分录行");
        return false;
      }
      return result;
    },
    /**
     * @description: 选择引用模板回调
     * @param list 选择 的分录数据
     */
    handleQuoteModel(list) {
      const itemList = this.vcdata.itemList;
      itemList.splice(itemList.length - 1, 0, ...list);
    },
    /**
     * @description: 现金流量科目 设置确认
     * @param list  被设置非现金流量科目
     * @return:
     */
    cashFlowSubmit(list) {
      const _this = this;
      const { itemList } = this.vcdata;
      itemList.forEach((itm, idx) => {
        if (Number(itm.cashSubject) !== 1) {
          const item = _this.getChangedItem(list, itm.subjectId);
          if (item) {
            itemList.splice(idx, 1, item);
          }
        }
      });
      this.cashProjectIsBalance = true;
    },
    getChangedItem(list, id) {
      const rsp = list.filter((itm) => itm.subjectId === id);
      return rsp[0] || "";
    },
    /**
     * @description: 获取凭证来源名称
     * @param value
     */
    getRefIdName(value) {
      let txt = "";
      const item = value[0];
      if (!item) {
        txt = "无";
      } else if (item.busTemplateId) {
        if (value.length > 1) {
          txt = `${item.name}（${value.length}）`;
        } else {
          txt = item.name;
        }
      } else {
        txt = item.name;
      }
      txt = txt || "无";
      return txt;
    },
    /**
     * @description: 凭证来源穿透
     * @param data
     */
    throughVoucher(data) {
      if (data.value && data.value.length && data.value[0].id) {
        this.showVoucherRelation(data.value, this.vcdata.voucherData.voucherType);
      }
    },
    /**
     * @description:  显示凭证关联的凭证或者业务数据
     * @param list
     * @param vType  凭证类型
     */
    async showVoucherRelation(list, vType) {
      if (!list || !list.length) {
        return;
      }
      if (vType === "001") {
        this.resetData();
        this.detailId = list[0].id;
        this.operationType = 2;
        await this.queryVoucherDetail();
        this.renderBtn();
      } else {
        // 业务建模列表
        this.busTemplateId = list[0].busTemplateId;
        const ids = [];
        list.forEach((itm) => {
          ids.push(itm.id);
        });
        this.sourceId = ids.join(",");
        this.$refs.popBusGrid.visible = true;
      }
    },
    /**
     * @description:  切换上一个下一个凭证详情
     * @param type
     */
    async switchVoucherDetail(type) {
      const _this = this;
      let index = this.voucherIdAry.findIndex((itm) => itm === _this.detailId);
      index = type === "prev" ? index - 1 : index + 1;
      if (index > -1 && this.voucherIdAry[index]) {
        this.resetData();
        this.operationType = 2;
        this.detailId = this.voucherIdAry[index];
        await this.queryVoucherDetail();
        this.renderBtn();
      } else if (index < 0) {
        Message("当前为第一页");
      } else if (index > this.voucherIdAry.length - 1) {
        Message("当前为最后一页");
      }
    },
    /**
     * @description:  触发自定义按钮
     * @param btnKey
     */
    async emitCustomBtn(btnKey) {
      const _this = this;
      const ids = this.voucherId;
      const config = this.vcdata.voucherData.customBtnList.filter((itm) => itm.btnKey === btnKey)[0];
      const { jumpPage } = config;
      await intelligentService.emitCustomBtn({ btnKey, ids });
      Message({
        message: "操作成功",
        onClose() {
          if (jumpPage === 1) { // 跳转列表
            _this.visible = false;
          }
        }
      });
    },
    /**
     * @description: 是否显示 现金流量科目设置 按钮
     */
    checkShowCashBtn() {
      if (this.operationType === 5) {
        this.showCashBtn = false;
        return;
      }

      let has1 = false; let has2 = false;
      let td = 0; let tc = 0; // 现金流量科目 借贷
      const list = this.vcdata.itemList.slice(0, this.vcdata.itemList.length - 1);
      has1 = list.some((itm) => (itm.subjectId && Number(itm.cashSubject) === 1));
      has2 = list.some((itm) => (itm.subjectId && Number(itm.cashSubject) !== 1));
      list.forEach((itm) => {
        if (Number(itm.cashSubject) === 1 || (Number(itm.cashSubject) !== 1 && (itm.cashFlowItem || (itm.amoundD && itm.dCashPro) || (itm.cCashPro || BarProp.amountC)))) {
            td += Number(itm.amountD);
            tc += Number(itm.amountC);
        }
      });

      this.cashProjectIsBalance = Math.abs(executeNum(td, tc, 1, 2)) === 0;
      this.showCashBtn = has1 && has2;
    },
    /**
     * @description:  手动设置现金流量
     */
    setCashFlowItem() {
      const rd = this.getRowData();
      if (rd) {
        this.submitParam = { itemList: rd };
        this.$refs.cashFlowMod.visible = true;
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.voc-detail-alert{
  height: calc(100% - 65px);
  margin-top: 65px;
  & /deep/ .el-dialog__header{
    border-bottom: 1px solid #dce5ec;
    .title-wrap{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right{
        margin-right: 20px;
        display: flex;
        align-items: center;
        >div{
          margin-right: 10px;
        }
      }
    }
  }
  & /deep/ .el-dialog__body{
    height: calc(100% - 57px);
  }
}
.voc-detail-wrap {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  font-size: 12px;
  position: relative;
  .top-row {
    line-height: 70px;
    display: flex;
    flex-wrap: wrap;
    .through{
      color:#179eda;
      text-decoration:underline;
      cursor: pointer;
    }
  }
  .top-row-item {
    overflow: hidden;
    text-align: left;
    display: flex;
    margin-right: 6%;
    & /deep/ .el-date-editor {
      width: 180px;
    }
    .el-input {
      width: 180px;
    }
  }
}
.grid-wrap {
  width: 100%;
  min-height: 300px;
  height: calc(100% - 400px);
  overflow-y: hidden;
}
.middle-row {
  height: 70px;
  line-height: 70px;
  border-bottom: 1px solid #dcdfe6;
  display: flex;
  > div {
    text-align: left;
    width: 20%;
    &:last-child {
      text-align: right;
      width: 60%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      .el-input {
        width: 280px;
        margin-left: 10px;
      }
      .suffix {
        position: absolute;
        font-size: 12px;
        color: #91a1b7;
        right: 10px;
      }
    }
  }
  &.grey{
    color: #91a1b7;
  }
}
.bottom-row {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .foot-upload {
    display: inline-block;
    margin-left: 10px;
    .en-icon{
      vertical-align: -0.2rem;
    }
  }
  .col-right{
    display: flex;
    .en-icon{
      cursor: pointer;
    }
  }
}
.file-row {
  min-height: 120px;
  .img-lis {
    text-align: left;
    .img-li {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .file-lis {
    text-align: left;
    .file-li {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
.btn-row {
  height: 60px;
  line-height: 60px;
  text-align: right;
  border-top: 1px solid #dce5ec;
}
.btn-switch{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  color: #ffffff;
  background: rgba(169,181,198,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  z-index: 999;
  cursor: pointer;
  transform: translateY(-50%);
  &:hover{
    background: rgba(169,181,198,0.9);
  }
  &.disabled{
    background: rgba(169,181,198,0.5);
    color: rgba(255, 255, 255, 0.5);
  }
  &.btn-right-voc{
    right: 0;
  }
}
</style>
