var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-li" },
    [
      _c("en-image", {
        attrs: {
          src: _vm.attr.src,
          alt: _vm.attr.name,
          "preview-src-list": _vm.imgList,
        },
      }),
      !_vm.disable
        ? _c("en-icon", {
            attrs: { name: "shanchu-liebiao", size: "small" },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.closeClick.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }